import { useContext, useEffect } from 'react';
import { PersonContext } from '../contexts/PersonContext/PersonContext';
import useMarketingPreferenceQuery from './queries/useMarketingPreferencesQuery';

import usePersonSummaryQuery from './queries/usePersonSummaryQuery';

const usePerson = () => {
  const personContext = useContext(PersonContext);
  const { data: person } = usePersonSummaryQuery(personContext.personId);
  const { data: marketingPreferences } = useMarketingPreferenceQuery(
    personContext.personId
  );

  useEffect(() => {
    if (person && personContext.setPerson) {
      personContext.setPerson(person.detail);
    }
  }, [person, personContext]);

  useEffect(() => {
    if (marketingPreferences && personContext.setMarketingPreferences) {
      personContext.setMarketingPreferences(marketingPreferences.detail);
    }
  }, [marketingPreferences, personContext]);

  return personContext;
};

export default usePerson;
