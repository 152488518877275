import { Price, PriceItem } from '../../api';

const isDiscount = (item: PriceItem) => item.name === 'Discount';

const isNotDiscount = (item: PriceItem) => !isDiscount(item);

const getDiscount = (price?: Price): PriceItem | undefined => {
  const discount = price?.items?.find(isDiscount);

  if (discount && discount.value !== 0) {
    return discount;
  }

  return undefined;
};

const getDiscountValue = (price?: Price) =>
  price?.items?.filter(isNotDiscount).reduce((acc, item) => {
    if (typeof item?.value === 'number') {
      return acc + item.value;
    }
    return acc;
  }, 0);

export { getDiscount, getDiscountValue };
