import clsx from 'clsx';
import { useEffect, useState } from 'react';
import Icon, { IconNames } from '../../DataDisplay/Icon/Icon';
import Label from '../../DataDisplay/Label/Label';

export type SliderProps<V extends { id: string }> = {
  id: string;
  name: string;
  values: V[];
  value: V;
  valueDisplay: (value: V) => JSX.Element;
  onChange: (value: V) => void;
  onBlur: () => void;
  onRelease?: (value: V, previousValue: V) => void;
  minLabel: {
    icon: IconNames;
    text: string;
  };
  maxLabel: {
    icon: IconNames;
    text: string;
  };
  disabled?: boolean;
  ariaLabel?: string;
};

const Slider = <V extends { id: string }>({
  id,
  name,
  values,
  value,
  valueDisplay,
  onChange,
  onBlur,
  onRelease,
  minLabel,
  maxLabel,
  disabled = false,
  ariaLabel,
}: SliderProps<V>) => {
  const [valueIndex, setValueIndex] = useState(
    values.findIndex(x => x?.id === value?.id) >= 0
      ? values.findIndex(x => x?.id === value?.id)
      : 0
  );
  const [previousIndex, setPreviousIndex] = useState<number | undefined>();
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (value) {
      const index =
        values.findIndex(x => x?.id === value?.id) >= 0
          ? values.findIndex(x => x?.id === value?.id)
          : 0;
      if (index !== valueIndex) {
        setValueIndex(index);
      }
    }
  }, [value, valueIndex, values]);

  useEffect(() => {
    setPercentage((valueIndex / (values.length - 1)) * 100);
  }, [valueIndex, values.length, percentage, values]);

  const handleOnChange = (e: string) => {
    setValueIndex(parseInt(e, 10));
    onChange(values[parseInt(e, 10)]);
  };

  const activeColour = `rgba(${getComputedStyle(document.body).getPropertyValue(
    '--slider-active'
  )})`;
  const inactiveColour = `rgba(${getComputedStyle(document.body).getPropertyValue(
    '--slider-inactive'
  )})`;

  const resetPreviousIndex = () => {
    setPreviousIndex(valueIndex);
  };

  const release = () => {
    if (onRelease && previousIndex !== undefined && !disabled) {
      onRelease(value, values[previousIndex]);
    }
    setPreviousIndex(undefined);
  };

  return (
    <div className="">
      <div
        style={{ left: `calc(${percentage}% + (${16 - percentage * 0.32}px))` }}
        className={clsx(
          `inline-flex relative after:absolute after:top-full justify-center items-center px-5 mb-6 w-16 after:w-0 h-16 after:h-0 text-center after:content-[''] rounded-lg after:border-8 after:border-transparent -translate-x-1/2 text-main-content-1 bg-content-background after:border-t-content-background`,
          { 'opacity-40': disabled }
        )}
      >
        {valueDisplay(values[valueIndex])}
      </div>

      <div className="relative">
        <input
          id={id}
          name={name}
          type="range"
          min="0"
          max={values.length - 1}
          value={valueIndex}
          onChange={e => handleOnChange(e.target.value)}
          onMouseDown={resetPreviousIndex}
          onTouchStart={resetPreviousIndex}
          onKeyDown={resetPreviousIndex}
          onMouseUp={() => {
            onBlur();
            release();
          }}
          onTouchEnd={() => {
            onBlur();
            release();
          }}
          onKeyUp={release}
          onBlur={onBlur}
          className={clsx(
            'w-full h-2 rounded-lg focus-visible:outline-1 focus:outline-none appearance-none bg-slider-inactive focus-visible:outline-main-content-1 slider-thumb',
            { 'opacity-40': disabled }
          )}
          style={{
            background:
              // eslint-disable-next-line prefer-template
              `linear-gradient(to right, ${activeColour} 0%, ${activeColour} 
              ${percentage}%, ${inactiveColour} ${percentage}%, ${inactiveColour} 100%)`,
          }}
          disabled={disabled}
          aria-label={ariaLabel}
        />
        <div
          style={{ left: `calc(${percentage}% + (${16 - percentage * 0.32}px))` }}
          className={clsx(
            'inline-flex absolute -translate-x-1/2 translate-y-2 pointer-events-none text-slider-handle',
            {
              'opacity-40': disabled,
            }
          )}
        >
          <Icon name="chevron-left" size="0.75rem" />
          <Icon name="chevron-right" size="0.75rem" />
        </div>
      </div>

      <div className="flex mt-4">
        <Label
          name={minLabel.text}
          icon={minLabel.icon}
          htmlFor={id}
          disabled={disabled}
          className="text-main-content-2"
        />
        <Label
          name={maxLabel.text}
          icon={maxLabel.icon}
          htmlFor={id}
          disabled={disabled}
          className="ml-auto text-main-content-2"
        />
      </div>
    </div>
  );
};

export default Slider;
