import { Card, Icon, LoadingSpinner } from '@dayinsure/components';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import usePolicyInfoQuery from '../../../hooks/queries/usePolicyInfoQuery';
import ContentContainer from '../../Layout/ContentContainer/ContentContainer';
import PolicyInfoComponent from '../../Account/PolicyInfo/PolicyInfoComponent';
import useReferrer from '../../../hooks/useReferrer';
import usePerson from '../../../hooks/usePerson';
import ConvertGuestDialog from '../GuestAccountConversion/ConvertGuestDialog';
import PageContainer from '../../Layout/PageContainer/PageContainer';
import useSignIn from '../../../hooks/useSignIn';
import { DayInsureAPI } from '../../../api/DayInsureAPI';
import { OpenAPI } from '../../../api';

const QuotePolicyInfo = () => {
  const { t } = useTranslation('quotes');
  const { isFullAccount } = usePerson();
  const referrer = useReferrer();
  const { signIn } = useSignIn();
  const [creatingAccount, setCreatingAccount] = useState(false);

  const { policyId } = useParams();
  const {
    data: policy,
    error: fetchPolicyError,
    isLoading: fetchingPolicy,
  } = usePolicyInfoQuery(policyId);

  const createPolicySession = useCallback(async () => {
    const api = new DayInsureAPI(OpenAPI);

    await api.policy.postApiV1PolicyCreateSession(policyId || '').then(response => {
      const sessionId = response.detail as string;
      localStorage.removeItem('LINK-POLICY-TO-ACCOUNT');
      localStorage.setItem('LINK-POLICY-TO-ACCOUNT', sessionId);
    });

    signIn();
  }, [policyId, signIn]);

  const policyData = useMemo(() => policy?.detail, [policy]);

  return (
    <div className="mb-24 w-full">
      {policyData && !fetchPolicyError && !fetchingPolicy ? (
        <PageContainer>
          <ContentContainer>
            <>
              <h1 className="self-center my-8 text-xl text-center sm:my-12">
                {t('policy.title')}
              </h1>

              {!isFullAccount && referrer.customerAccessEnabled && (
                <>
                  <Card paddingLevel="small" classNames="flex items-center my-8 sm:mt-12">
                    <Icon className="text-main-content-1" size="2rem" name="tip" />
                    <div className="ml-6 text-main-content-1">
                      <div className="block">
                        <Trans
                          i18nKey="policy.create_account"
                          t={t}
                          components={[
                            <button
                              type="button"
                              className="font-semibold underline text-link"
                              onClick={() => setCreatingAccount(true)}
                            >
                              Create an account
                            </button>,
                          ]}
                        />
                      </div>
                      <>
                        <div className="block lg:mt-4">
                          <Trans
                            i18nKey="policy.existing_account"
                            t={t}
                            components={[
                              <button
                                type="button"
                                className="link"
                                id="policy-info-login"
                                onClick={createPolicySession}
                              >
                                Add to an existing account
                              </button>,
                            ]}
                          />
                        </div>
                      </>
                    </div>
                  </Card>
                </>
              )}

              <PolicyInfoComponent policyId={policyId} policyData={policy} canExtend />
            </>
          </ContentContainer>
        </PageContainer>
      ) : (
        <LoadingSpinner centered className="mt-12" />
      )}
      <ConvertGuestDialog
        open={creatingAccount}
        onClose={() => {
          setCreatingAccount(false);
        }}
      />
    </div>
  );
};

export default QuotePolicyInfo;
