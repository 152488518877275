/**
 * Clamps a provided number between a minimum and maximum
 * @param num The number to be clamped
 * @param min The minimum value
 * @param max The maximum value
 * @returns The clamped value
 */
const clamp = (num: number, min: number, max: number) => {
  if (min > max) {
    throw new Error('Minimum must be smaller than maximum');
  }
  return Math.min(Math.max(num, min), max);
};

export default clamp;
