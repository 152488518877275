import { ReactNode } from 'react';
import clsx from 'clsx';
import Icon, { IconNames } from '../../../DataDisplay/Icon/Icon';

type CardListItemProps = {
  asideItem?: ReactNode;
  children: ReactNode;
  icon: IconNames;
  label: ReactNode;
  testId: string;
  className?: string;
  iconSize?: string;
  labelClassName?: string;
};

export const CardListItem = ({
  children,
  className,
  icon,
  iconSize,
  label,
  testId,
  asideItem,
  labelClassName,
}: CardListItemProps) => {
  return (
    <div className={clsx('mt-4', className)} data-testid={testId}>
      <div className="flex grow justify-between items-center mb-2 space-between">
        <span
          data-testid={`${testId}_label`}
          className={clsx(
            'flex gap-2 items-center text-sm leading-3 opacity-60',
            labelClassName
          )}
        >
          <Icon name={icon} size={iconSize} />
          {label}
        </span>
        {asideItem}
      </div>
      <div
        data-testid={`${testId}_content`}
        className="min-h-[1.5rem] leading-6"
        id="dashboard-last-quote-driver"
      >
        {children}
      </div>
    </div>
  );
};
