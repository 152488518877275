import { useEffect, useState } from 'react';
import useScript, { ScriptStatus } from './useScript';

/**
 * Fetches a blackbox fingerprint. Uses the 'getBlackbox' approach, rather
 * than the hidden form field. The docs suggest that the function isn't guarenteed
 * to return the id on the first request, so it should be attempted multiple times.
 *
 * This will attempt to fetch an id ten times, with 500ms wait between each attempt.
 * All the testing I've done so far has returned a value on the first attempt though.
 *
 * docs: https://developer.bazaarvoice.com/conversations-api/tutorials/submission/device-fingerprinting/iovation-web
 */
const useBlackBoxId = () => {
  const scriptStatus = useScript('/js/iovation_loader.js');
  const [blackBoxId, setBlackBoxId] = useState<string | undefined>();
  const [fetchAttempts, setFetchAttempts] = useState(0);

  const attemptToFetchBBId = () => {
    if (typeof window.IGLOO.getBlackbox === 'function') {
      const bbData = window.IGLOO.getBlackbox();
      if (bbData.finished && bbData.blackbox) {
        setBlackBoxId(bbData.blackbox);
        setFetchAttempts(0);
      } else {
        setFetchAttempts(attempts => attempts + 1);
      }
    } else {
      setFetchAttempts(attempts => attempts + 1);
    }
  };

  useEffect(() => {
    if (!blackBoxId && fetchAttempts > 0 && fetchAttempts < 10) {
      setTimeout(attemptToFetchBBId, 500);
    }
  }, [fetchAttempts, blackBoxId]);

  useEffect(() => {
    if (scriptStatus === ScriptStatus.READY) {
      attemptToFetchBBId();
    }
  }, [scriptStatus]);

  return blackBoxId;
};

export default useBlackBoxId;
