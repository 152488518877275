/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductResponseIAPIResult } from '../models/ProductResponseIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get product returns required information for a product
   * @param productName
   * @param assetType
   * @param underwriter
   * @param brandName
   * @param properties
   * @param age
   * @returns ProductResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Product(
    productName: string,
    assetType?: string,
    underwriter?: string,
    brandName?: string,
    properties?: Record<string, string>,
    age?: string,
  ): CancelablePromise<ProductResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{productName}',
      path: {
        'productName': productName,
      },
      query: {
        'assetType': assetType,
        'underwriter': underwriter,
        'brandName': brandName,
        'properties': properties,
        'age': age,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

}