import { VehicleFuelType, VehicleTransmissionType, VehicleType } from '../../api';

const query = {
  vehicleReg: 'vehicle-reg',
  vehicleMakes: (vehicleType?: VehicleType, registrationYear?: string) => [
    'vehicle-makes',
    { vehicleType: vehicleType || '', registrationYear: registrationYear || '' },
  ],
  vehicleModels: (
    vehicleType: VehicleType | undefined,
    vehicleMake: string | undefined,
    options: {
      transmission?: VehicleTransmissionType;
      fuelType?: VehicleFuelType;
      registrationYear?: string;
    }
  ) => [
    'vehicle-models',
    {
      vehicleType: vehicleType || '',
      vehicleMake: vehicleMake || '',
      options: {
        transmission: options.transmission || '',
        fuelType: options.fuelType || '',
        registrationYear: options.registrationYear || '',
      },
    },
  ],
  occupations: (occupationQuery: string) => ['occupations', occupationQuery],
  addressLookup: (postcode: string) => ['address-lookup', postcode],
  addressDetail: (postkey: string) => ['address-details', postkey],
  quoteDetails: (quoteId: string) => ['quote', quoteId],
  quoteDocuments: (quoteId: string) => ['web-documents', quoteId],
  quoteSummary: 'quote-summary',
  policySummary: 'policy-summary',
  person: 'person',
  personAssets: 'person-assets',
  policy: (policyId: string, queryKey = '') => ['policy', policyId, queryKey],
  policyDocsList: (policyId: string) => ['policy-docs-list', policyId],
  policyDocDownload: (policyId: string, docType: string) => [
    'policy-doc-download',
    policyId,
    docType,
  ],
  product: (productName: string, age: string) => ['product', { productName, age }],
  acceptanceCriteria: (quoteId?: string) => ['acceptance-criteria', quoteId],
  paymentMethods: 'payment-methods',
};

export default query;
