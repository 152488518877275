import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ApiError, DayInsureAPI, OpenAPI } from '../../api';
import GlobalErrorContext from '../../contexts/GlobalErrorContext/GlobalErrorContext';
import useNavigateWithReferrer from '../useNavigateWithReferrer';
import query from './queryKeys';
import useSignIn from '../useSignIn';
import useReferrer from '../useReferrer';
import usePerson from '../usePerson';
import { getAcrValues } from '../useAuthConfig';

const usePolicyInfoQuery = (policyId: string | undefined, queryKey = '') => {
  const { t } = useTranslation('account');
  const navigate = useNavigateWithReferrer();
  const { signIn } = useSignIn();
  const { isFullAccount } = usePerson();
  const referrer = useReferrer();

  const { setGlobalError } = useContext(GlobalErrorContext);

  const getPolicy = () => {
    const api = new DayInsureAPI(OpenAPI);

    return api.policy.getApiV1Policy(policyId || '');
  };

  return useQuery(query.policy(policyId || '', queryKey), getPolicy, {
    keepPreviousData: false,
    retry: (count, error: ApiError) =>
      error.status !== 404 && error.status !== 401 && count < 3,
    enabled: policyId !== undefined,

    onError: async err => {
      if (err.status === 401 && !isFullAccount) {
        const api = new DayInsureAPI(OpenAPI);
        const response = await api.policy.getApiV1PolicyAuthDetails(policyId || '');

        if (response.detail?.isAuthenticated) {
          return signIn();
        }
        if (response.detail?.securityId) {
          return signIn({
            extraQueryParams: {
              acr_values: getAcrValues(referrer, response.detail?.securityId),
            },
            state: {
              quoteIdForReAccess: policyId,
            },
          });
        }
      }

      return setGlobalError(err, {
        cta: t('policy_info.error_cta'),
        onClose: () => {
          navigate({
            pathname: `/policies`,
          });
        },
      });
    },
  });
};

export default usePolicyInfoQuery;
