import Icon from '../../../DataDisplay/Icon/Icon';
import { FooterLinkProps } from '../FooterLink';

export const FooterHomepageLink = ({ href, children }: FooterLinkProps) => (
  <a
    className="inline-flex gap-2 pr-4 pl-6 font-lato text-sm font-bold sm:pr-3 sm:pl-0 md:pr-6 text-footer-content-1 link-with-icon"
    href={href}
    id="footer-go-to-dayinsure-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
    <Icon name="link" className="text-footer-content-1" size="10px" />
  </a>
);
