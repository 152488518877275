const getFPStatic = () => {
  const env = process.env.REACT_APP_IOVATION_FP_STATIC;
  switch (env) {
    case 'true':
      return true;
    case 'false':
    default:
      return false;
  }
};

const configureIovation = () => {
  window.IGLOO = window.IGLOO || {
    enable_rip: true, // Enable Real IP protection.
    enable_flash: false, // Disable flash
    install_flash: false, // Don't ask user to install flash
    loader: {
      version: 'general5', // Non-experimental 5.x updates
      fp_static: getFPStatic(), // Don't load 1st party resources
    },
  };
};

export default configureIovation;
