import { SigninRedirectArgs } from 'oidc-client-ts';
import { useCallback, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import RouterState from '../types/RouterState';
import usePerson from './usePerson';
import useReferrer from './useReferrer';
import useSearchParamsToBuildPermittedQueryParams from '../helpers/url/permittedQueryStringBuilderFromSearchParams';

type SignInArgsType = Omit<SigninRedirectArgs, 'state'> & {
  state?: RouterState;
};

/**
 * This hook re-exports the oidc sign in method. It automatically appends the referrer to the
 * state object and checks whether the user is signed in as a guest, in which case it will
 * force the user to be prompted for login details
 */
const useSignIn = () => {
  const { signinRedirect } = useAuth();
  const { isGuest } = usePerson();
  const { name: referrerName } = useReferrer();

  const [isSigningIn, setIsSigningIn] = useState(false);

  const completeQueryParamsFromJourney = useSearchParamsToBuildPermittedQueryParams();

  const signIn = useCallback(
    (args?: SignInArgsType) => {
      setIsSigningIn(true);
      const forceLoginPrompt = isGuest ? { prompt: 'login' } : null;
      const signInArgs: SigninRedirectArgs = {
        ...args,
        state: {
          ...args?.state,
          referrerName,
          completeQueryParamsFromJourney,
        },
        extraQueryParams: {
          ...args?.extraQueryParams,
          ...forceLoginPrompt,
        },
      };
      signinRedirect(signInArgs).finally(() => {
        setIsSigningIn(false);
      });
    },
    [isGuest, referrerName, signinRedirect, completeQueryParamsFromJourney]
  );

  return { signIn, isSigningIn };
};

export default useSignIn;
