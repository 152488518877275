import React, { useMemo, useState } from 'react';
import { GetPersonReponse, MarketingPreference } from '../../api';

type PersonAuthDetails = {
  id: string;
  isFullAccount: boolean;
  isGuest: boolean;
};

type PersonContextType = {
  personId?: string;
  isFullAccount?: boolean;
  isGuest?: boolean;
  setPersonAuthDetails?: (authDetails?: PersonAuthDetails) => void;
  person?: GetPersonReponse;
  setPerson?: (person?: GetPersonReponse) => void;
  marketingPreferences?: Array<MarketingPreference> | null;
  setMarketingPreferences?: (
    preferences: Array<MarketingPreference> | null | undefined
  ) => void;
};

export const PersonContext = React.createContext<PersonContextType>({
  isGuest: false,
  isFullAccount: false,
});

export const PersonProvider = ({ children }: { children: React.ReactNode }) => {
  const [personAuthDetails, setPersonAuthDetails] = useState<
    PersonAuthDetails | undefined
  >();
  const [person, setPerson] = useState<GetPersonReponse | undefined>();
  const [marketingPreferences, setMarketingPreferences] = useState<
    Array<MarketingPreference> | null | undefined
  >();

  const context = useMemo(
    () => ({
      personId: personAuthDetails?.id,
      isGuest: personAuthDetails?.isGuest,
      isFullAccount: personAuthDetails?.isFullAccount,
      setPersonAuthDetails,
      person,
      setPerson,
      marketingPreferences,
      setMarketingPreferences,
    }),
    [
      personAuthDetails?.id,
      personAuthDetails?.isGuest,
      personAuthDetails?.isFullAccount,
      person,
      marketingPreferences,
    ]
  );

  return <PersonContext.Provider value={context}>{children}</PersonContext.Provider>;
};
