import Icon from '../Icon/Icon';

const Avatar = () => {
  return (
    <div className="flex justify-center items-center w-8 h-8 rounded-full text-header-background bg-header-content-1">
      <Icon name="user" />
    </div>
  );
};

export default Avatar;
