/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OccupationListIAPIResult } from '../models/OccupationListIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OccupationService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns a list of occupations with the option to filter occupations by text similarity
   * will return warningtype
   * @param match Provide this text to only recieve similar occupations
   * @param take Limit the maximum number of occupations to be returned
   * @returns OccupationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Occupation(
    match?: string,
    take?: number,
  ): CancelablePromise<OccupationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/occupation',
      query: {
        'match': match,
        'take': take,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

}