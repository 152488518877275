import { ReactNode } from 'react';

export type FooterLinkProps = { href: string; id: string; children: ReactNode };

export const FooterLink = ({ href, children, id }: FooterLinkProps) => (
  <a
    href={href}
    className="font-lato text-sm text-footer-content-1"
    id={id}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
