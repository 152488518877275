import { useLayoutEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';

type RadialProgressProps = {
  /** Id for the overall component */
  id: string;
  /** Id for the progress tracker label */
  labelId: string;
  /** ID for the day value number */
  dayValueId: string;
  /** The progress percentage */
  progressPercentage: number;
  /** Label at the top of the circle describing what is due */
  reminderLabel: string;
  /** Large number in the middle of the circle */
  daysNumber: number;
  /** Days label */
  daysLabel: string;
};

const RadialProgress = ({
  id,
  labelId,
  dayValueId,
  progressPercentage,
  reminderLabel,
  daysNumber,
  daysLabel,
}: RadialProgressProps) => {
  const radialRef = useRef<HTMLDivElement>(null);
  const [sqSize, setSize] = useState<number | undefined>();
  const radius = ((sqSize || 0) - 8) / 2;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * progressPercentage) / 100;

  useLayoutEffect(() => {
    const updateSize = throttle(() => {
      if (radialRef.current) {
        const w = radialRef.current.offsetWidth;
        setSize(w > 144 ? 144 : w);
      }
    }, 500);
    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [radialRef]);

  return (
    <div
      className="w-full max-w-[8rem] max-h-[8rem] lg:max-w-[9rem] lg:max-h-[9rem]"
      ref={radialRef}
    >
      <svg id={id} className="w-full" style={{ height: sqSize }}>
        {sqSize && (
          <>
            <circle
              className="fill-transparent stroke-progress-2"
              cx={sqSize / 2}
              cy={sqSize / 2}
              r={radius}
              strokeWidth="8px"
            />
            <circle
              className="rounded-sm fill-transparent stroke-progress-1"
              cx={sqSize / 2}
              cy={sqSize / 2}
              r={radius}
              strokeWidth="8px"
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset,
                strokeLinecap: 'round',
              }}
            />
            <text
              className="text-sm fill-main-content-2"
              x="50%"
              y="30%"
              dy=".3em"
              textAnchor="middle"
              id={labelId}
            >
              {reminderLabel}
            </text>
            <text
              className="text-xl fill-main-content-1"
              x="50%"
              y="50%"
              dy=".3em"
              textAnchor="middle"
              id={dayValueId}
            >
              {daysNumber}
            </text>
            <text
              className="text-base fill-main-content-1"
              x="50%"
              y="70%"
              dy=".3em"
              textAnchor="middle"
            >
              {daysLabel}
            </text>
          </>
        )}
      </svg>
    </div>
  );
};

export default RadialProgress;
