import moment from 'moment';
import * as Yup from 'yup';
import {
  combinedRegistrationRegex,
  emailRegex,
  postcodeRegex,
} from '../../../constants/regex';
import { PaymentValidationSchema } from '../../../types/PaymentFields';
import validateDrivingLicence from '../licence-validation';
import { QuoteJourneyRoutes } from './QuoteJourneySchema';
import { isValidContactNumber } from './validation';

export const RegistrationValidationSchema = Yup.object().shape({
  vehicleRegistration: Yup.string()
    .matches(combinedRegistrationRegex, {
      message: 'Please check this',
      excludeEmptyString: true,
    })
    .required('Required'),
});

export const ManualVehicleEntryValidationSchema = Yup.object().shape({
  vehicleType: Yup.object().required('Required'),
  yearOfManufacture: Yup.object().when('vehicleType.id', {
    is: 'Motorhome',
    then: Yup.object().notRequired(),
    otherwise: Yup.object().required('Required'),
  }),
  vehicleMake: Yup.object().required('Required'),
  fuelType: Yup.object().when('vehicleType.id', {
    is: 'Motorhome',
    then: Yup.object().notRequired(),
    otherwise: Yup.object().required('Required'),
  }),
  gearboxType: Yup.object().when('vehicleType.id', {
    is: 'Motorhome',
    then: Yup.object().notRequired(),
    otherwise: Yup.object().required('Required'),
  }),
  vehicleModel: Yup.object().required('Required'),
});

export const DriverDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Please check this')
    .max(20, 'Please check this')
    .required('Required'),
  surname: Yup.string()
    .min(2, 'Please check this')
    .max(20, 'Please check this')
    .required('Required'),
  dateOfBirth: Yup.object().shape({
    parsedDate: Yup.date()
      .nullable()
      .required('Please check this')
      .max(moment.utc().subtract(17, 'years').add(28, 'days'), 'Please check this'),
    day: Yup.string().required('Required'),
    month: Yup.string().required('Required'),
    year: Yup.string().required('Required'),
  }),
  licenceNumber: Yup.string()
    .min(16, 'Please check this')
    .max(16, 'Please check this')
    .required('Required')
    .test('licence_num', 'Please check this', (value, ctx) => {
      return validateDrivingLicence(
        value,
        ctx.parent.firstName,
        ctx.parent.surname,
        ctx.parent.dateOfBirth.parsedDate
      );
    }),
  occupation: Yup.object().required('Required'),
});

export const ContactDetailsValidationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .matches(emailRegex, {
      message: 'Please check this',
      excludeEmptyString: true,
    })
    .required('Required'),
  mobileNumber: Yup.string()
    .required('Required')
    .test('phone-num', 'Please check this', value => {
      if (!value) {
        return false;
      }
      return isValidContactNumber(value);
    }),
  postcode: Yup.string()
    .matches(postcodeRegex, {
      message: 'Please check this',
      excludeEmptyString: true,
    })
    .required('Required'),
  postkey: Yup.object().required('Required'),
  address: Yup.object().required('Required'),
});

export const LearnerDriverValidationSchema = Yup.object().shape({
  driverLicenseTest: Yup.object().required('Required'),
  registeredKeeper: Yup.object().required('Required'),
  registeredKeeperFirstName: Yup.string().when('registeredKeeper.id', {
    is: 'registeredKeeper_no',
    then: Yup.string()
      .min(2, 'Please check this')
      .max(20, 'Please check this')
      .required('Required'),
  }),
  registeredKeeperSurname: Yup.string().when('registeredKeeper.id', {
    is: 'registeredKeeper_no',
    then: Yup.string()
      .min(2, 'Please check this')
      .max(20, 'Please check this')
      .required('Required'),
  }),
  registeredKeeperMobileNumber: Yup.string().when('registeredKeeper.id', {
    is: 'registeredKeeper_no',
    then: Yup.string()
      .required('Required')
      .test('phone-num', 'Please check this', value => {
        if (!value) {
          return false;
        }
        return isValidContactNumber(value);
      }),
  }),
  registeredKeeperAddressQuestion: Yup.object().when('registeredKeeper.id', {
    is: 'registeredKeeper_no',
    then: Yup.object().required('Required'),
  }),
  registeredKeeperPostcode: Yup.string().when('registeredKeeperAddressQuestion.id', {
    is: 'registeredKeeperAddress_no',
    then: Yup.string()
      .matches(postcodeRegex, {
        message: 'Please check this',
        excludeEmptyString: true,
      })
      .required('Required'),
  }),
  registeredKeeperPostkey: Yup.object().when('registeredKeeperAddressQuestion.id', {
    is: 'registeredKeeperAddress_no',
    then: Yup.object().required('Required'),
  }),
  registeredKeeperAddress: Yup.object().when('registeredKeeper.id', {
    is: 'registeredKeeper_no',
    then: Yup.object().required('Required'),
  }),
  registeredKeeperInsured: Yup.object().when('registeredKeeper.id', {
    is: 'registeredKeeper_no',
    then: Yup.object().required('Required'),
  }),
});

export const CoverLengthValidationSchema = Yup.object().shape({
  coverStartQuestion: Yup.object().required('Required'),
  coverLength: Yup.object().required('Required'),
  exactStartDate: Yup.object().when('coverStartQuestion.id', {
    is: 'EXACT',
    then: Yup.object().shape({
      parsedDate: Yup.date()
        .nullable()
        .required('Please check this')
        .min(moment().utc().startOf('day'), 'Start date cannot be in the past')
        .max(moment().utc().add(28, 'days'), 'Can only be up to 28 days ahead'),
      day: Yup.string().required('Required'),
      month: Yup.string().required('Required'),
      year: Yup.string().required('Required'),
    }),
  }),
  exactStartHour: Yup.object().when('coverStartQuestion.id', {
    is: 'EXACT',
    then: Yup.object().required('Required'),
  }),
  exactStartMinute: Yup.object().when('coverStartQuestion.id', {
    is: 'EXACT',
    then: Yup.object().required('Required'),
  }),
});

export const getValidationSchemaFromRoute = (route: string) => {
  if (route.includes(QuoteJourneyRoutes.RegistrationSearch))
    return RegistrationValidationSchema;
  if (route.includes(QuoteJourneyRoutes.ManualVehicleEntry))
    return ManualVehicleEntryValidationSchema;
  if (route.includes(QuoteJourneyRoutes.DriverDetails))
    return DriverDetailsValidationSchema;
  if (route.includes(QuoteJourneyRoutes.ContactDetails))
    return ContactDetailsValidationSchema;
  if (route.includes(QuoteJourneyRoutes.LearnerDriver))
    return LearnerDriverValidationSchema;
  if (route.includes(QuoteJourneyRoutes.CoverLength)) return CoverLengthValidationSchema;
  if (route.includes(QuoteJourneyRoutes.Payment)) return PaymentValidationSchema;

  return undefined;
};
