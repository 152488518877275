/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebApiAddressIAPIResult } from '../models/WebApiAddressIAPIResult';
import type { WebApiAddressWithPostkeyListIAPIResult } from '../models/WebApiAddressWithPostkeyListIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AddressService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns a list of addresses for a given postcode each with an accompanying postkey to uniquely identify the address and a short summary
   * @param postcode REQUIRED - A valid UK Postcode e.g. A9 9AA
   * @param houseNameOrNumber OPTIONAL - The house number of the desired address used to restrict matching results
   * @returns WebApiAddressWithPostkeyListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Address(
    postcode: string,
    houseNameOrNumber?: string,
  ): CancelablePromise<WebApiAddressWithPostkeyListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/address/{postcode}',
      path: {
        'postcode': postcode,
      },
      query: {
        'houseNameOrNumber': houseNameOrNumber,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a detailed address given a postkey (returned from an address postcode lookup)
   * @param postkey REQUIRED - A unique string identifier for an address (returned from an address summary)
   * @returns WebApiAddressIAPIResult Success
   * @throws ApiError
   */
  public getApiV1AddressDetail(
    postkey: string,
  ): CancelablePromise<WebApiAddressIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/address/{postkey}/detail',
      path: {
        'postkey': postkey,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

}