import { format } from 'date-fns';
import { TFunction } from 'react-i18next';
import { GetPolicyResponseIAPIResult, PolicyState } from '../../../../api';
import { fullCountdown } from '../../../../helpers/timeUtils/timeCountdown';

const getContentFromState = (
  t: TFunction<'policy'>,
  policyState?: PolicyState,
  policyData?: GetPolicyResponseIAPIResult
): string | undefined => {
  switch (policyState) {
    case PolicyState.ACTIVE: {
      const endDate = policyData?.detail?.policy?.endDate;
      if (!endDate) return undefined;

      return t('active.ends_in', {
        countdown: fullCountdown(endDate),
      });
    }
    case PolicyState.UPCOMING: {
      const startDate = policyData?.detail?.policy?.startDate;
      if (!startDate) return undefined;

      return t('active.starts_in', {
        countdown: fullCountdown(startDate),
      });
    }
    case PolicyState.CANCELLATION_REQUESTED: {
      const cancellationDate = policyData?.detail?.policy?.cancellationDate;
      if (!cancellationDate) return undefined;

      return t('cancellation_requested.requested_at', {
        date: format(new Date(cancellationDate), 'dd/MM/yyyy HH:mm'),
      });
    }
    case PolicyState.CANCELLED:
    case PolicyState.EXPIRED:
    default:
      return undefined;
  }
};

const getLabelFromState = (
  t: TFunction<'policy'>,
  policyState?: PolicyState
): string | undefined => {
  switch (policyState) {
    case PolicyState.ACTIVE:
    case PolicyState.UPCOMING:
    case PolicyState.CANCELLED:
    case PolicyState.EXPIRED:
      return t('active.label', { state: policyState });
    case PolicyState.CANCELLATION_REQUESTED:
      return t('cancellation_requested.label');
    default:
      return undefined;
  }
};

export { getContentFromState, getLabelFromState };
