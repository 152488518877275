import { Button } from '@dayinsure/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { flatTyreLarge, flatTyreMedium, flatTyreSmall } from '../../assets/pageNotFound';
import generateSrcSet from '../../helpers/images/generateSrcSet';
import useNavigateWithReferrer from '../../hooks/useNavigateWithReferrer';
import ContentContainer from '../Layout/ContentContainer/ContentContainer';
import PageContainer from '../Layout/PageContainer/PageContainer';

const PageNotFound = () => {
  const { t } = useTranslation('common');
  const navigateWithReferrer = useNavigateWithReferrer();

  const handleCtaClick = () => {
    navigateWithReferrer('/quote');
  };

  return (
    <PageContainer>
      <ContentContainer className="flex flex-col items-center">
        <h1 className="mt-12 mb-8 text-xl text-center">{t('page_not_found.title')}</h1>
        <img
          src={flatTyreSmall}
          srcSet={generateSrcSet(flatTyreSmall, flatTyreMedium, flatTyreLarge)}
          className="object-cover w-[270px] h-[180px] sm:w-[348px] sm:h-[230px]"
          alt=""
        />
        <p className="mb-8 text-lg text-center">{t('page_not_found.message')}</p>
        <Button
          id="cta-page-not-found"
          text={t('page_not_found.cta')}
          onClick={handleCtaClick}
          fullWidth
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default PageNotFound;
