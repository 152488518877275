/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IAPIResult } from '../models/IAPIResult';
import type { PaymentMethodResponseIAPIResult } from '../models/PaymentMethodResponseIAPIResult';
import type { SavePaymentMethodRequest } from '../models/SavePaymentMethodRequest';
import type { SavePaymentMethodResponseIAPIResult } from '../models/SavePaymentMethodResponseIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentMethodService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Authorized - Saves a new payment method for the user
   * @param requestBody
   * @returns SavePaymentMethodResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PaymentMethod(
    requestBody?: SavePaymentMethodRequest,
  ): CancelablePromise<SavePaymentMethodResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payment-method',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Client Error`,
      },
    });
  }

  /**
   * Get all available payment methods as well as existing saved payment methods
   * @param amount OPTIONAL - amount
   * @returns PaymentMethodResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PaymentMethod(
    amount?: string,
  ): CancelablePromise<PaymentMethodResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payment-method',
      query: {
        'amount': amount,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Deletes an existing saved payment method from the users account
   * @param paymentMethodId
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public deleteApiV1PaymentMethod(
    paymentMethodId: string,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/payment-method/{paymentMethodId}',
      path: {
        'paymentMethodId': paymentMethodId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}