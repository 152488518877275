import { ProductName } from '../../api';
import { QuoteJourneyFormData } from '../forms/quote';

const inferProductName = (values: QuoteJourneyFormData) => {
  if (!values.isLearner) {
    return ProductName.SHORT_TERM_MOTOR;
  }

  if (values.registeredKeeper?.id === 'registeredKeeper_yes') {
    return ProductName.LEARNER_OWN_MOTOR;
  }

  return ProductName.LEARNER_TOP_UP_MOTOR;
};

export default inferProductName;
