import { Avatar, Icon, Menu } from '@dayinsure/components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import useReferrer from '../../../hooks/useReferrer';
import LinkWithReferrer from '../../Common/Links/LinkWithReferrer';
import useSearchParamsToBuildPermittedQueryParams from '../../../helpers/url/permittedQueryStringBuilderFromSearchParams';

const AccountMenu = (): JSX.Element => {
  const { t } = useTranslation('header');
  const referrer = useReferrer();
  const auth = useAuth();
  const linkClasses = (active: boolean) =>
    clsx(
      'flex gap-3 items-center py-4 px-8 w-full text-md font-semibold leading-4 text-menu-content-1 ',
      { 'bg-menu-content-2': active }
    );
  const completeQueryParamsFromJourney = useSearchParamsToBuildPermittedQueryParams();
  return (
    <Menu
      position="left"
      trigger={
        <button
          id="account-user-avatar-btn-menu"
          type="button"
          aria-label={t('account.open')}
        >
          <Avatar />
        </button>
      }
    >
      <Menu.Item>
        {({ active }) => (
          <LinkWithReferrer
            data-testid="account-menu-account-details-btn-menu"
            to="/account/details"
            className={clsx(linkClasses(active), 'mt-4')}
          >
            <Icon name="user" />
            {t('account.account_details')}
          </LinkWithReferrer>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <LinkWithReferrer
            data-testid="account-menu-contact-details-btn-menu"
            to="/account/contact-details"
            className={linkClasses(active)}
          >
            <Icon name="email" />
            {t('account.contact_details')}
          </LinkWithReferrer>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <LinkWithReferrer
            data-testid="account-menu-saved-card-btn-menu"
            to="/account/saved-cards"
            className={linkClasses(active)}
          >
            <Icon name="cards" />
            {t('account.saved_cards')}
          </LinkWithReferrer>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <LinkWithReferrer
            data-testid="account-menu-password-btn-menu"
            to="/account/password"
            className={linkClasses(active)}
          >
            <Icon name="lock" />
            {t('account.password')}
          </LinkWithReferrer>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button
            type="button"
            data-testid="account-menu-logout-btn-menu"
            className={clsx(linkClasses(active), 'mb-4')}
            onClick={() => {
              auth.signoutRedirect({
                id_token_hint: auth.user?.id_token,
                post_logout_redirect_uri: `${window.origin}/logout`,
                state: {
                  referrerName: referrer.name,
                  completeQueryParamsFromJourney,
                },
              });
            }}
          >
            <Icon name="logout" />
            {t('account.logout')}
          </button>
        )}
      </Menu.Item>
    </Menu>
  );
};

export default AccountMenu;
