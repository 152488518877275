/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmAccountEmailRequest } from '../models/ConfirmAccountEmailRequest';
import type { ConvertToCustomerRequest } from '../models/ConvertToCustomerRequest';
import type { CreateMarketingPreferenceRequest } from '../models/CreateMarketingPreferenceRequest';
import type { CreatePersonRequest } from '../models/CreatePersonRequest';
import type { CreatePersonResponseIAPIResult } from '../models/CreatePersonResponseIAPIResult';
import type { GetPersonReponseIAPIResult } from '../models/GetPersonReponseIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { LicenceIAPIResult } from '../models/LicenceIAPIResult';
import type { MarketingPreferenceListIAPIResult } from '../models/MarketingPreferenceListIAPIResult';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdatePersonRequest } from '../models/UpdatePersonRequest';
import type { UpdatePersonResponseIAPIResult } from '../models/UpdatePersonResponseIAPIResult';
import type { VerifyEmailAddressRequest } from '../models/VerifyEmailAddressRequest';
import type { WebAssetListIAPIResult } from '../models/WebAssetListIAPIResult';
import type { WebConfirmPasswordResetRequest } from '../models/WebConfirmPasswordResetRequest';
import type { WebPolicySummaryListIAPIResult } from '../models/WebPolicySummaryListIAPIResult';
import type { WebQuoteSummaryListIAPIResult } from '../models/WebQuoteSummaryListIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PersonService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Creates a new person object given properties of that person
   * @param requestBody Request containing a persons details.
   * @returns CreatePersonResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1Person(
    requestBody?: CreatePersonRequest,
  ): CancelablePromise<CreatePersonResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Sends a password reset email.
   * @param email The email address of the account to reset the password.
   * @param requestBody A request containing the Return Url.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonSendPasswordReset(
    email: string,
    requestBody?: SendPasswordResetRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{email}/send-password-reset',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Authorized - Gets a person given an ID
   * @param id An ID representing a person
   * @returns GetPersonReponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Person(
    id: string,
  ): CancelablePromise<GetPersonReponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Updates an existing person given the id.  Properties given will be added or updated
   * @param id An ID representing a person
   * @param requestBody Request containing the persons details to update. Any details in the request (including nulls) will be updated
   * @returns UpdatePersonResponseIAPIResult Success
   * @throws ApiError
   */
  public putApiV1Person(
    id: string,
    requestBody?: UpdatePersonRequest,
  ): CancelablePromise<UpdatePersonResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Gets a licence given an ID
   * @param id An ID representing a person
   * @returns LicenceIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonLicence(
    id: string,
  ): CancelablePromise<LicenceIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/licence',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Updates the current password with the new password provided. The requester must be authorised as the person who owns this account.
   * @param personId An ID representing a person
   * @param requestBody Request containing the current and new password
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonChangePassword(
    personId: string,
    requestBody?: PasswordChangeRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{personId}/change-password',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Gets a person's policy summaries given a person ID.
   * @param id An ID representing a person
   * @returns WebPolicySummaryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonPolicySummary(
    id: string,
  ): CancelablePromise<WebPolicySummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/policy-summary',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Gets a list of quotes for a person ordered from most to least recently quoted.
   * @param id An ID representing a person
   * @param take The maximum number of quotes to be returned, this is limited to a max of 10
   * @returns WebQuoteSummaryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonQuoteSummary(
    id: string,
    take?: number,
  ): CancelablePromise<WebQuoteSummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/quote-summary',
      path: {
        'id': id,
      },
      query: {
        'take': take,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - An API to get marketing preferences for a customer
   * @param id
   * @returns MarketingPreferenceListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonMarketingPreferences(
    id: string,
  ): CancelablePromise<MarketingPreferenceListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/marketing-preferences',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - allows a customers marketing preferences to be updated against their account
   * @param id
   * @param requestBody
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonMarketingPreferences(
    id: string,
    requestBody?: CreateMarketingPreferenceRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}/marketing-preferences',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Authorized - Returns a list of assets that the customer has previously used
   * @param id
   * @returns WebAssetListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonAsset(
    id: string,
  ): CancelablePromise<WebAssetListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/asset',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Sends a new email verification email to the given email address if an unverified person with the email address exists
   * @param email The email address to send the verification to
   * @param requestBody Model containing the return Url
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonSendEmailVerification(
    email: string,
    requestBody?: SendVerificationEmailRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{email}/send-email-verification',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Verifies the email address of the user by security id.
   * @param id The security id representing the person identification
   * @param requestBody Model containing the email details to be verified
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonVerifyEmailAddress(
    id: string,
    requestBody?: VerifyEmailAddressRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{id}/verify-email-address',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Verifies the email address of the user by security id.
   * @param id
   * @param requestBody
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonConvertToCustomer(
    id: string,
    requestBody?: ConvertToCustomerRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}/convert-to-customer',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Confirms the reset of the given persons password setting it the the new given value
   * @param id The security id representing the person identification
   * @param requestBody Model containing the password reset details
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonConfirmPasswordReset(
    id: string,
    requestBody?: WebConfirmPasswordResetRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{id}/confirm-password-reset',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Sends a new email verification email to the given personId if an unverified person with the email address exists
   * @param personId The email address to send the verification to
   * @param requestBody request containing the return Url
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonConfirmAccountEmail(
    personId: string,
    requestBody?: ConfirmAccountEmailRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/confirm-account-email',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}