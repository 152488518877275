import { TFunction } from 'react-i18next';
import { AddOnType } from '../../../../api';
import { Addon } from '../cover-addons.types';

const getAddonTitle = (t: TFunction<'quotes' | 'policy'>, addon?: Addon) => {
  const addTypeTitleMap: Record<AddOnType, string> = {
    BreakdownOnwardTravel: t('cover_addons.rac_complete_addon.title'),
    BreakdownRoadside: t('cover_addons.rac_basic_addon.title'),
    ExcessProtection: t('cover_addons.excess_protection_addon.title'),
    ComprehensiveEuropeanCover: t('cover_addons.comp_cover_addon.title'),
  };

  if (!addon?.type || !addTypeTitleMap[addon?.type]) {
    return addon?.name || '';
  }

  return addTypeTitleMap[addon.type];
};

export default getAddonTitle;
