import clsx from 'clsx';

export type LogoButtonProps = {
  onClick?: VoidFunction;
  logoSize: 'regular' | 'large';
  logo: string;
  altLogoText: string;
};

export const LogoButton = ({ onClick, logoSize, logo, altLogoText }: LogoButtonProps) => {
  const img = (
    <img
      id="header-dayinsure-logo"
      className={clsx({
        'h-4 xs:h-6': logoSize === 'regular',
        'h-6 xs:h-8': logoSize === 'large',
      })}
      src={logo}
      alt={altLogoText}
    />
  );

  if (onClick) {
    return (
      <button type="button" onClick={onClick}>
        {img}
      </button>
    );
  }

  return img;
};
