import clsx from 'clsx';
import { ReactNode } from 'react';
import { CardListItem } from './CardListItem';

export type CardProps = {
  paddingLevel?: 'small' | 'large' | 'none';
  classNames?: string;
  testId?: string;
  children: ReactNode;
  withBackground?: boolean;
};

const Card = ({
  paddingLevel = 'small',
  classNames,
  testId,
  children,
  withBackground = true,
}: CardProps): JSX.Element => {
  return (
    <div
      className={clsx(
        'h-max rounded-lg',
        { 'bg-content-background': withBackground },
        { 'p-4 md:p-6 lg:p-8': paddingLevel === 'small' },
        { 'p-6 sm:p-12 lg:p-16': paddingLevel === 'large' },
        classNames
      )}
      data-testid={testId}
    >
      {children}
    </div>
  );
};

Card.CardListItem = CardListItem;

export default Card;
