import { LoadingSpinner } from '@dayinsure/components';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ErrorDialog from '../Common/ErrorDialog/ErrorDialog';
import PageContainer from '../Layout/PageContainer/PageContainer';
import cup from '../../assets/cup.png';
import { DayInsureAPI, OpenAPI } from '../../api';
import useReferrer from '../../hooks/useReferrer';
import { getAcrValues } from '../../hooks/useAuthConfig';
import NavigateWithReferrer from '../Common/Links/NavigateWithReferrer';
import useSignIn from '../../hooks/useSignIn';
import useNavigateWithReferrer from '../../hooks/useNavigateWithReferrer';
import usePerson from '../../hooks/usePerson';
import useEffectOnce from '../../hooks/useEffectOnce';

/**
 * As of 29/07/22, the auth-details endpoint doesn't return a policyId
 * if the quote has been purchased, meaning we need to use /quote/{id}/policy
 * to fetch the id. Given that this is an authenticated api, it means we can't
 * fetch it to generate a redirect url before going to identity server.
 *
 * For now, we store the quote param and then go back to this page and repeat the
 * flow again in order to redirect to the correct place.
 *
 * The flow can be improved if we get a policyId back from auth-details, as we coule
 * go directly to the correct page after authentication.
 */
const Retail = () => {
  const { t } = useTranslation('retail');
  const [params] = useSearchParams();
  const navigate = useNavigateWithReferrer();
  const { signIn } = useSignIn();
  const { isFullAccount } = usePerson();
  const referrer = useReferrer();
  const [error, setError] = useState(false);
  const quoteId = params.get('quote');

  // Redirect to the correct page. If the resource is a policy, we fetch the policyId
  const redirectToResource = useCallback(
    (id: string, resourceType: string) => {
      if (resourceType === 'Quote') {
        navigate(`/quote/${id}/cover-length`, { replace: true });
      } else if (resourceType === 'Policy') {
        const api = new DayInsureAPI(OpenAPI);
        api.quote
          .getApiV1QuotePolicy(id)
          .then(res => {
            if (res.detail?.policy?.id)
              navigate(
                isFullAccount
                  ? `/policies/${res.detail?.policy?.id}`
                  : `/policy/${res.detail?.policy?.id}/info`,
                { replace: true }
              );
          })
          .catch(() => {
            setError(true);
          });
      } else {
        setError(true);
      }
    },
    [navigate, isFullAccount]
  );

  // Go to the re-access enquiry
  const initiateReauthentication = useCallback(
    (securityId: string, id: string) => {
      signIn({
        extraQueryParams: {
          acr_values: getAcrValues(referrer, securityId),
        },
        state: {
          quoteIdForReAccess: id,
        },
      });
    },
    [signIn, referrer]
  );

  // On load, call auth-details and determine whether to redirect or trigger re-access enquiry
  // We've a short timeout to make sure that the auth provider has had time to load
  useEffectOnce(() => {
    if (quoteId) {
      setTimeout(() => {
        const api = new DayInsureAPI(OpenAPI);
        api.quote
          .getApiV1QuoteAuthDetails(quoteId)
          .then(response => {
            if (response.detail?.isAuthenticated) {
              redirectToResource(quoteId, response.detail?.resourceType || '');
            } else if (response.detail?.securityId) {
              initiateReauthentication(response.detail.securityId, quoteId);
            } else {
              setError(true);
            }
          })
          .catch(() => {
            // check if a quoteId is a policyId
            api.policy
              .getApiV1PolicyAuthDetails(quoteId)
              .then(() => {
                navigate(`/policy/${quoteId}/info`);
              })
              .catch(() => setError(true));
          });
      }, 500);
    }
  });

  if (!quoteId) {
    return <NavigateWithReferrer to={{ pathname: '/' }} />;
  }

  return (
    <PageContainer>
      <div className="flex flex-col mt-8 mb-16 w-full sm:mt-12 md:mb-24 text-main-content-1">
        <h1
          className="self-center mb-6 text-xl text-center"
          id="payment-processing-heading"
        >
          {t('loading_your_details')}
        </h1>
        <img src={cup} alt="cup" className="mx-auto h-60" />
        <h2 className="self-center mb-8 text-lg text-center">{t('please_wait')}</h2>
        <LoadingSpinner size="regular" centered />
      </div>
      <ErrorDialog
        open={error}
        onClose={() => {
          navigate({
            pathname: `/quote`,
          });
        }}
        title={t('error_title')}
        error={t('error_message')}
        ctaId="retail-error-cta"
        ctaMessage={t('error_cta')}
      />
    </PageContainer>
  );
};

export default Retail;
