import { Popover, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

type DrawerProps = {
  /** The component which can be clicked to open the Drawer */
  trigger: JSX.Element;
  /** The content of the drawer */
  children: JSX.Element | JSX.Element[];
};

const Drawer = ({ trigger, children }: DrawerProps) => {
  return (
    <Popover>
      <Popover.Button as={Fragment}>{trigger}</Popover.Button>
      <Transition
        as={Fragment}
        enter="transition-transform duration-200"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition-transform duration-150"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <Popover.Panel className="fixed top-0 left-0 z-40 w-screen h-screen -translate-x-full md:w-auto text-menu-content bg-menu-background text-menu-content-1">
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

Drawer.Close = Popover.Button;

export default Drawer;
