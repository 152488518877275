import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../../api';
import { GlobalErrorProvider } from '../../../contexts/GlobalErrorContext/GlobalErrorContext';
import ErrorDialog from '../ErrorDialog/ErrorDialog';

type SetGlobalErrorType = {
  title?: string;
  message?: string;
  cta?: string;
  ctaId?: string;
  onClose?: () => void;
};

const GlobalErrorWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { t } = useTranslation('common');
  const [errorContext, setErrorContext] = useState<{
    hasError: boolean;
    title?: string;
    message?: string;
    cta?: string;
    ctaId?: string;
    onClose?: () => void;
  }>({
    hasError: false,
  });

  const setError = (errorObject?: unknown, values?: SetGlobalErrorType) => {
    setErrorContext({
      hasError: true,
      title: values?.title || undefined,
      message:
        values?.message ||
        (errorObject as ApiError).body?.messages?.[0].message ||
        (errorObject as Error).message ||
        undefined,
      cta: values?.cta || undefined,
      ctaId: values?.ctaId || undefined,
      onClose: values?.onClose || undefined,
    });
  };

  // Clear the error state
  // Keep the visible text values so that they don't change before the dialog closes.
  // They will automatically be overwritten when the dialog is next show
  const clearError = () =>
    setErrorContext({
      hasError: false,
      title: errorContext.title,
      message: errorContext.message,
      cta: errorContext.cta,
      ctaId: undefined,
      onClose: undefined,
    });

  return (
    <GlobalErrorProvider
      value={{
        error: errorContext.hasError,
        title: errorContext.title,
        message: errorContext.message,
        cta: errorContext.cta,
        ctaId: errorContext.ctaId,
        onClose: errorContext.onClose,
        setGlobalError: setError,
        clearGlobalError: clearError,
      }}
    >
      {children}
      <ErrorDialog
        open={errorContext.hasError}
        onClose={() => {
          if (errorContext.onClose) {
            errorContext.onClose();
          }
          clearError();
        }}
        title={errorContext.title || t('errors.dialog.something_went_wrong')}
        error={errorContext.message || t('errors.dialog.generic_message')}
        ctaMessage={errorContext.cta || t('errors.dialog.try_again')}
        ctaId={errorContext.ctaId || 'generic-error-dialog-cta'}
      />
    </GlobalErrorProvider>
  );
};

export default GlobalErrorWrapper;
