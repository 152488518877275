import { AssetType, VehicleType } from '../api';

export const assetTypeToVehicleType = (type: AssetType) => {
  switch (type) {
    case AssetType.MOTORHOME:
      return VehicleType.MOTORHOME;
    case AssetType.MOTOR_CAR:
      return VehicleType.MOTOR_CAR;
    case AssetType.VAN_LCV:
      return VehicleType.VAN_LCV;
    case AssetType.NONE:
    default:
      return VehicleType.NONE;
  }
};

export const vehicleTypeToAssetType = (type: VehicleType) => {
  switch (type) {
    case VehicleType.MOTORHOME:
      return AssetType.MOTORHOME;
    case VehicleType.MOTOR_CAR:
      return AssetType.MOTOR_CAR;
    case VehicleType.VAN_LCV:
      return AssetType.VAN_LCV;
    case VehicleType.NONE:
    default:
      return AssetType.NONE;
  }
};
