import Icon from '@dayinsure/components/src/components/DataDisplay/Icon/Icon';

import { useTranslation } from 'react-i18next';
import LinkWithReferrer from '../../Common/Links/LinkWithReferrer';

const NewQuoteButton = (): JSX.Element => {
  const { t } = useTranslation('header');

  return (
    <LinkWithReferrer
      id="header-get-new-quote-btn"
      className="box-border inline-flex gap-x-2 justify-center items-center px-4 h-8 font-raleway text-sm font-semibold bg-transparent rounded-lg border-2 text-header-content-1 border-header-content-1"
      to="/quote"
    >
      <Icon name="policies" size="0.75rem" />
      <span>{t('new_quote')}</span>
    </LinkWithReferrer>
  );
};

export default NewQuoteButton;
