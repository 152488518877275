import clsx from 'clsx';
import clamp from '../../../helpers/clamp/clamp';

type StepperProps = {
  /** An array containing the name of each step */
  steps: string[];
  /** The index of the current step. Will be clamped within the number of steps provided */
  currentStepIndex: number;
  /** The progress within the current step. Will be clamped between 0 and 1. Defaults to 0.5 */
  currentStepPercentage?: number;
};

const Stepper = ({
  steps,
  currentStepIndex,
  currentStepPercentage = 0.5,
}: StepperProps) => {
  const currentStepClamped = clamp(currentStepIndex, 0, steps.length - 1);
  const currentStepPercentageClamped = clamp(currentStepPercentage, 0, 1);
  const singleStepPercentage = 1 / steps.length;
  const barProgressPercentage =
    singleStepPercentage * currentStepClamped +
    singleStepPercentage * currentStepPercentageClamped;

  return (
    <div className="flex relative flex-col w-full">
      <div className="hidden visible gap-[2px] mb-2 w-full md:flex">
        {steps.length > 0 &&
          steps.map((step, index) => (
            <span
              key={`Step ${index + 1} name`}
              title={step}
              className={clsx(
                'flex-1 leading-5 truncate transition-colors',
                { 'text-main-content-1': currentStepClamped === index },
                { 'text-main-content-2': currentStepClamped > index },
                { 'text-main-content-4': currentStepClamped < index }
              )}
            >
              {step}
            </span>
          ))}
      </div>
      <div
        className={clsx(
          'flex overflow-hidden relative w-full h-2 rounded-lg bg-progress-2'
        )}
      >
        <div
          className="absolute w-full h-2 transition-transform duration-300 origin-left bg-progress-1"
          style={{ transform: `scale(${barProgressPercentage}, 1)` }}
        />
        {steps.length > 0 &&
          steps.map((_, index) => (
            <div
              key={`Step ${index + 1} bar`}
              className="z-10 grow last:border-0 border-r-2 opacity-60 border-page-background-from"
            />
          ))}
      </div>
    </div>
  );
};

export default Stepper;
