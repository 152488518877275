import useScript from './useScript';

const useCookieConsent = () => {
  const showCookieConsent = process.env.REACT_APP_SHOW_COOKIE_CONSENT === 'true';

  useScript(
    showCookieConsent
      ? `https://cdn-ukwest.onetrust.com/consent/${process.env.REACT_APP_COOKIE_CONSENT_KEY}/OtAutoBlock.js`
      : ''
  );
  useScript(
    showCookieConsent
      ? `https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js`
      : '',
    {
      'data-domain-script': process.env.REACT_APP_COOKIE_CONSENT_KEY || '',
      charset: 'UTF-8',
    }
  );
  return undefined;
};

export default useCookieConsent;
