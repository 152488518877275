const getNextSignificantStartTime = () => {
  const currentDateTime = new Date();
  const periodMinutes = 15;

  const currentMinutes = currentDateTime.getMinutes();
  const newMinutes =
    currentMinutes +
    (currentMinutes % periodMinutes === 0
      ? periodMinutes
      : periodMinutes * 2 - (currentMinutes % periodMinutes));

  const date = new Date(currentDateTime);
  date.setMinutes(newMinutes);

  return date;
};

export default getNextSignificantStartTime;
