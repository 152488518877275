import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import referrers from '../constants/referrers';
import Referrer from '../types/referrer';

const getReferrer = (referrerName: string) => {
  return Object.values(referrers).find(
    ref => ref.name.toLowerCase() === referrerName.toLowerCase()
  );
};

export const getReferrerNameFromOidcState = (oidcStateCode: string) => {
  const oidcStateString = localStorage.getItem(`oidc.${oidcStateCode}`);
  const referrerName = oidcStateString
    ? JSON.parse(oidcStateString)?.data?.referrerName
    : undefined;
  return referrerName || 'dayinsure';
};

const useReferrerFetcher = (): Referrer => {
  const location = useLocation();
  const [searchParams] = useSearchParams({ referrer: '' });

  const determineReferrer = useCallback(() => {
    let referrerName = 'Dayinsure';
    const referrerParam = searchParams.get('referrer');
    const oidcStateParam = searchParams.get('state');

    if (referrerParam) {
      referrerName = referrerParam;
    } else if (oidcStateParam) {
      referrerName = getReferrerNameFromOidcState(oidcStateParam);
    }

    return getReferrer(referrerName) || referrers.dayinsure;
  }, [searchParams]);

  const [referrer, setReferrer] = useState(determineReferrer());

  useEffect(() => {
    setReferrer(determineReferrer());
  }, [determineReferrer, location]);

  return referrer;
};

export default useReferrerFetcher;
