import React, { useMemo, useState } from 'react';
import { AssetType, ProductName } from '../../api';

type DocumentContextType = {
  underwriter: string;
  product: ProductName;
  assetType: AssetType;
  setUnderwriter: (underwriter: string) => void;
  setProduct: (product: ProductName) => void;
  setAssetType: (assetType: AssetType) => void;
};

/**
 * This context is used to provide dynamic document links across the site.
 * The current underwriter, product and asset type can be set from anywhere
 * in the site and will be read by any instances of the useDocumentQuery hook.
 */
export const DocumentContext = React.createContext<DocumentContextType>({
  underwriter: 'aviva',
  product: ProductName.SHORT_TERM_MOTOR,
  assetType: AssetType.MOTOR_CAR,
  setUnderwriter: () => {},
  setProduct: () => {},
  setAssetType: () => {},
});

export const DocumentProvider = ({ children }: { children: React.ReactNode }) => {
  const [underwriter, setUnderwriter] = useState('aviva');
  const [product, setProduct] = useState(ProductName.SHORT_TERM_MOTOR);
  const [assetType, setAssetType] = useState(AssetType.MOTOR_CAR);

  const context = useMemo(
    () => ({
      underwriter,
      setUnderwriter,
      product,
      setProduct,
      assetType,
      setAssetType,
    }),
    [assetType, product, underwriter]
  );

  return <DocumentContext.Provider value={context}>{children}</DocumentContext.Provider>;
};
