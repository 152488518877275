import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Icon, Card, Button } from '@dayinsure/components';
import { useFormikContext } from 'formik';
import { useSearchParams } from 'react-router-dom';
import VehicleReg from '../VehicleReg/VehicleReg';
import { QuoteJourneyFormData } from '../../../../helpers/forms/quote';
import ContentContainer from '../../../Layout/ContentContainer/ContentContainer';
import LinkWithReferrer from '../../../Common/Links/LinkWithReferrer';
import useLazyPreload from '../../../../hooks/useLazyPreload';
import useReferrer from '../../../../hooks/useReferrer';
import usePerson from '../../../../hooks/usePerson';
import useSignIn from '../../../../hooks/useSignIn';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import Advert from './Advert';

const RegistrationSearch = () => {
  const { t } = useTranslation('quotes');
  const { isFullAccount } = usePerson();
  const { signIn } = useSignIn();
  const { isSubmitting, resetForm } = useFormikContext<QuoteJourneyFormData>();
  const referrer = useReferrer();
  const preloadContext = useLazyPreload();
  const [showAdvert, setDisplayAdvert] = useState(true);
  const [searchParams] = useSearchParams();

  useEffectOnce(() => {
    preloadContext?.preloadManualVehicleSearch();
    preloadContext?.preloadDriverDetails();
  });

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleHideAdvert = () => setDisplayAdvert(false);
  const displayAdvert =
    showAdvert &&
    referrer.name.toLowerCase() === 'dayinsure' &&
    searchParams.get('referrer')?.toLowerCase() !== 'dts';

  return (
    <div className="flex flex-col justify-center w-full">
      <h1 className="mt-8 text-xl text-center md:mt-12 lg:mt-16 text-main-content-1">
        {t('vehicle.title')}
      </h1>
      <ContentContainer>
        <>
          {displayAdvert && <Advert onClose={handleHideAdvert} />}

          <Card classNames="flex-col md:!bg-content-background-prominent !bg-transparent !p-0 md:!p-16 items-center my-8 lg:my-12  w-full">
            <VehicleReg
              id="new-quote-registration-plate-input"
              name="vehicleRegistration"
            />
            <Button
              id="new-quote-get-quote-for-vehicle"
              submit
              fullWidth
              classNames="mt-10"
              loading={isSubmitting}
              icon={{ name: 'arrow-right', position: 'right' }}
              text={t('vehicle.CTAbutton')}
            />
          </Card>
          {/* <AppLinksCard messaging="WHEN_YOU_BUY" className="mb-8" />   */}

          {!isFullAccount && referrer.customerAccessEnabled && (
            <Card classNames="flex items-center mb-24" paddingLevel="small">
              <Icon className="text-main-content-1" size="2rem" name="tip" />
              <div className="ml-6 text-main-content-1">
                <Trans
                  i18nKey="vehicle.cardText"
                  t={t}
                  components={[
                    <button
                      type="button"
                      className="font-semibold underline text-link"
                      onClick={() => {
                        signIn();
                      }}
                    >
                      Login
                    </button>,
                    <LinkWithReferrer
                      to="/sign-up"
                      className="font-semibold underline text-link"
                    >
                      create an account
                    </LinkWithReferrer>,
                  ]}
                />
              </div>
            </Card>
          )}
        </>
      </ContentContainer>
    </div>
  );
};

export default RegistrationSearch;
