import { Icon } from '@dayinsure/components';
import { useTranslation } from 'react-i18next';
import RouterState from '../../../types/RouterState';
import LinkWithReferrer from '../Links/LinkWithReferrer';

const EditLink = ({ id, to, show }: { id: string; to: string; show: boolean }) => {
  const { t } = useTranslation('common');

  if (!show) return null;

  const routerState: RouterState = {
    editingQuote: true,
  };

  return (
    <LinkWithReferrer
      to={to}
      className="flex items-center text-sm font-semibold leading-[14px]"
      id={id}
      state={routerState}
    >
      <Icon name="edit" className="mr-2" size="12px" />
      {t('edit')}
    </LinkWithReferrer>
  );
};

export default EditLink;
