import React from 'react';
import { LazyFactory } from '../../helpers/lazyLoading/lazyLoading';

type LazyPreloadContextType = {
  preloadManualVehicleSearch: LazyFactory<unknown>;
  preloadDriverDetails: LazyFactory<unknown>;
  preloadContactDetails: LazyFactory<unknown>;
  preloadLearnerDriver: LazyFactory<unknown>;
  preloadCoverLength: LazyFactory<unknown>;
  preloadCoverAddons: LazyFactory<unknown>;
  preloadAcceptanceStatements: LazyFactory<unknown>;
  preloadReview: LazyFactory<unknown>;
  preloadPay: LazyFactory<unknown>;
  preloadCheckout: LazyFactory<unknown>;
};

const LazyPreloadContext = React.createContext<LazyPreloadContextType | undefined>(
  undefined
);

export const LazyPreloadProvider = LazyPreloadContext.Provider;
export default LazyPreloadContext;
