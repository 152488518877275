import { useQuery } from 'react-query';
import {
  ApiError,
  DayInsureAPI,
  GetPolicyDocumentsResponseIListIAPIResult,
  OpenAPI,
} from '../../api';
import query from './queryKeys';

const usePolicyDocsListQuery = (id: string | undefined) => {
  const getPolicyDocs = () => {
    const api = new DayInsureAPI(OpenAPI);

    return api.policy.getApiV1PolicyDocument(id || '');
  };

  return useQuery<GetPolicyDocumentsResponseIListIAPIResult, ApiError>(
    query.policyDocsList(id || ''),
    getPolicyDocs,
    {
      retry: (count, error: ApiError) => error.status !== 404 && count < 3,
      enabled: id !== undefined,
    }
  );
};

export default usePolicyDocsListQuery;
