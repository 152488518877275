interface DateOfBirthIngredients {
  year: string;
  month: string;
  day: string;
}

const validateDate = (dob: Record<string, number>): boolean => {
  let isValid = true;

  Object.values(dob).forEach(value => {
    if (Number.isNaN(value)) {
      isValid = false;
    }
  });

  return isValid;
};

const parseDateIngredient = (dateIngredient?: string): number =>
  parseInt(dateIngredient || '', 10);

export type { DateOfBirthIngredients };

export { parseDateIngredient, validateDate };
