import clsx from 'clsx';
import React from 'react';
import Icon from '../../DataDisplay/Icon/Icon';

type Variant = 'primary' | 'secondary';

export type CheckboxProps = {
  id: string;
  name: string;
  checked?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  label: string;
  disabled?: boolean;
  variant?: Variant;
  testId?: string;
};

const Checkbox = ({
  id,
  name,
  checked,
  onChange,
  onBlur,
  label,
  disabled,
  variant = 'primary',
  testId,
}: CheckboxProps) => {
  const isPrimary = variant === 'primary';
  const isSecondary = variant === 'secondary';

  return (
    <label
      htmlFor={id}
      className={clsx('flex items-center font-semibold cursor-pointer', {
        'opacity-40': disabled,
      })}
    >
      <div className="flex relative">
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          className={clsx(
            'w-6 h-6 bg-transparent rounded-[4px] border-2 transition-colors duration-200 appearance-none cursor-pointer',
            { 'checked:bg-white border-selector-border-1': isPrimary },
            {
              'checked:bg-popup-selector-active border-popup-selector-active':
                isSecondary,
            }
          )}
          disabled={disabled}
          data-testid={testId}
        />
        <Icon
          className={clsx(
            'absolute top-1/2 left-1/2 z-10 transition-opacity -translate-x-1/2 -translate-y-1/2 pointer-events-none',
            { 'opacity-100': checked },
            { 'opacity-0': !checked },
            { 'text-selector-active': isPrimary },
            { 'text-white': isSecondary }
          )}
          name="check"
        />
      </div>
      <span
        className={clsx(
          'ml-3 leading-5',
          { 'text-main-content-1': isPrimary },
          { 'text-popup-content-1': isSecondary }
        )}
      >
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
