import { memo } from 'react';
import { Icon } from '@dayinsure/components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useQuoteDocumentQuery from '../../../../../hooks/queries/useQuoteDocumentQuery';

type DocumentsProps = {
  labelContainerClass: string;
  labelClass: string;
  productName: string;
  quoteId: string;
};

const Documents = ({
  labelContainerClass,
  labelClass,
  productName,
  quoteId,
}: DocumentsProps) => {
  const { t } = useTranslation('quotes');
  const { data: productDocs, isLoading: docsAreLoading } = useQuoteDocumentQuery(quoteId);

  if (!productDocs) {
    return null;
  }

  return (
    <>
      <div className={labelContainerClass}>
        <span className={labelClass}>
          <Icon name="doc" />
          {t('review.documents_header', { product: productName })}
        </span>
      </div>
      <ul>
        {productDocs?.detail?.map(doc => {
          if (doc.documentUrl) {
            return (
              <li key={doc.fileName}>
                <a
                  key={doc.fileName}
                  href={doc.documentUrl}
                  className={clsx('text-sm text-white summary-link', {
                    'text-white/20': docsAreLoading,
                    'text-white': !docsAreLoading,
                  })}
                  target="_blank"
                  rel="noreferrer"
                >
                  {doc.description}
                </a>
              </li>
            );
          }
          return undefined;
        })}
      </ul>
    </>
  );
};

export default memo(Documents);
