import { useMutation, useQueryClient } from 'react-query';
import { CreateQuoteRequest, DayInsureAPI, OpenAPI, QuoteSummaryResponseIAPIResult } from '../../api';
import query from '../queries/queryKeys';

const useCreateQuoteMutation = () => {
  const queryClient = useQueryClient();

  const createQuote = (requestBody: CreateQuoteRequest) => {
    const api = new DayInsureAPI(OpenAPI);

    return api.quote.postApiV1Quote(requestBody);
  };

  return useMutation(createQuote, {
    onSuccess: data => {
      queryClient.setQueryData<QuoteSummaryResponseIAPIResult>(
        query.quoteDetails(data.detail?.id || ''),
        quote => {
          return {
            ...quote,
            detail: {
              ...quote?.detail,
              ...data.detail,
            },
          };
        }
      );
    },
  });
};

export default useCreateQuoteMutation;
