import { Drawer, Icon } from '@dayinsure/components';
import { useTranslation } from 'react-i18next';
import LinkWithReferrer from '../../Common/Links/LinkWithReferrer';

const MenuDrawer = (): JSX.Element => {
  const { t } = useTranslation('header');
  return (
    <Drawer
      trigger={
        <button
          type="button"
          className="flex gap-2 items-center font-raleway font-semibold text-header-content-2"
          id="mobile-menu-open-btn"
        >
          <Icon name="menu" className="text-header-content-1" />
          {t('menu.title')}
        </button>
      }
    >
      <div className="w-full md:w-80 text-menu-content-1">
        <div className="flex justify-between items-center py-4 px-6">
          <span className="text-lg leading-7">{t('menu.title')}</span>
          <Drawer.Close id="mobile-menu-close-btn" aria-label={t('menu.close')}>
            <Icon name="cross" />
          </Drawer.Close>
        </div>
        <hr className="text-menu-content-2" />
        <div className="py-8 px-6 space-y-8">
          <Drawer.Close
            as={LinkWithReferrer}
            id="mobile-menu-dashboard-btn"
            to="/dashboard"
            className="text-md font-semibold link-with-icon"
          >
            <Icon name="dashboard" size="12px" />
            {t('menu.dashboard')}
          </Drawer.Close>
          <Drawer.Close
            as={LinkWithReferrer}
            id="mobile-menu-policies-btn"
            to="/policies"
            className="text-md font-semibold link-with-icon"
          >
            <Icon name="policies" size="12px" />
            {t('menu.policies')}
          </Drawer.Close>
          <Drawer.Close
            as={LinkWithReferrer}
            id="mobile-menu-help-btn"
            to="/help"
            className="text-md font-semibold link-with-icon"
          >
            <Icon name="help" size="12px" />
            {t('menu.help')}
          </Drawer.Close>
        </div>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
