import { MarketConsentType, MarketingPreference } from '../../../api';

const mapUserMarketingPreferencesToReferrer = (
  userPrefs: Array<MarketingPreference>,
  referrerPrefs: Array<MarketConsentType>
) => {
  return referrerPrefs.map(pref => ({
    id: pref,
    name: pref,
    checked: userPrefs.find(p => p.type === pref)?.isConsented || false,
  }));
};

export default mapUserMarketingPreferencesToReferrer;
