import React from 'react';

type ContentContainerProps = {
  children?: JSX.Element | JSX.Element[];
  className?: string;
};

const ContentContainer = ({ children, className }: ContentContainerProps) => {
  return (
    <div
      className={`mx-auto max-w-tablet-container sm:min-w-tablet-container text-main-content-1 lg:max-w-desktop-container lg:min-w-desktop-container ${className}`}
    >
      {children}
    </div>
  );
};

export default ContentContainer;
