import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RAC_BREAKDOWN_NUMBER } from '../../../constants/contact';

type BreakdownNumberCardProps = {
  className?: string;
};

const BreakdownNumberCard = ({ className }: BreakdownNumberCardProps) => {
  const { t } = useTranslation('policy');

  return (
    <Card paddingLevel="small" classNames={clsx('flex items-center', className)}>
      <Icon className="text-main-content-1" size="2rem" name="phone" />
      <div className="ml-6 text-main-content-1" id="breakdown-contact-card">
        <Trans
          i18nKey="breakdown_contact.rac"
          t={t}
          values={{ number: RAC_BREAKDOWN_NUMBER }}
          components={[
            <a
              id="breakdown-contact-number"
              type="button"
              href={`tel:${RAC_BREAKDOWN_NUMBER}`}
              className="appearance-none link"
            >
              {RAC_BREAKDOWN_NUMBER}
            </a>,
          ]}
        />
      </div>
    </Card>
  );
};

export default BreakdownNumberCard;
