/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DocumentType {
  NOT_SPECIFIED = 'NotSpecified',
  ASSUMPTIONS = 'Assumptions',
  KEYFACTS = 'Keyfacts',
  UK_COVER_POLICY_WORDING = 'UkCoverPolicyWording',
  TOBA = 'Toba',
  BREAKDOWN_KEY_FACTS = 'BreakdownKeyFacts',
  BREAKDOWN_POLICY_WORDING = 'BreakdownPolicyWording',
  EUROPEAN_COVER_POLICY_WORDING = 'EuropeanCoverPolicyWording',
  LEGAL_EXPENSES_KEY_FACTS = 'LegalExpensesKeyFacts',
  LEGAL_EXPENSES_POLICY_WORDING = 'LegalExpensesPolicyWording',
  ASSUMPTIONS_MOTORHOME = 'Assumptions_Motorhome',
  LEGAL_EXPENSES_KEY_FACTS_MOTORHOME = 'LegalExpensesKeyFacts_Motorhome',
  LEGAL_EXPENSES_POLICY_WORDING_MOTORHOME = 'LegalExpensesPolicyWording_Motorhome',
  UK_COVER_POLICY_WORDING_MOTORHOME = 'UkCoverPolicyWording_Motorhome',
  INSURANCE_PRODUCT_INFORMATION_DOCUMENT = 'InsuranceProductInformationDocument',
  UK_COVER_KEY_FACTS_MOTORHOME = 'UkCoverKeyFacts_Motorhome',
  PRIVACY_POLICY = 'PrivacyPolicy',
  EXCESS_PROTECTION_POLICY_WORDING = 'ExcessProtectionPolicyWording',
  EXCESS_PROTECTION_KEY_FACTS = 'ExcessProtectionKeyFacts',
  EXCESS_CERTIFICATE = 'ExcessCertificate',
  POLICY_CERTIFICATE = 'PolicyCertificate',
}