import jwtDecode from 'jwt-decode';
import { useCallback, useContext, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { OpenAPI } from '../../../api';
import { PersonContext } from '../../../contexts/PersonContext/PersonContext';
import { ExtendPolicyRoutes } from '../../../helpers/forms/account/ExtendPolicyFormHelpers';
import { QuoteJourneyRoutes } from '../../../helpers/forms/quote';
import useTokenRefreshInterceptor from '../../../hooks/useTokenRefreshInterceptor';
import useEffectAfterMount from '../../../hooks/useEffectAfterMount';
import useReferrer from '../../../hooks/useReferrer';
import useSiteUnloadEffect from '../../../hooks/useSiteUnloadEffect';

type AuthEventsHandlerProps = {
  children: React.ReactNode;
};

const AuthEventsHandler = ({ children }: AuthEventsHandlerProps) => {
  const auth = useAuth();
  const location = useLocation();
  const referrer = useReferrer();
  const { setPersonAuthDetails, setPerson, isGuest } = useContext(PersonContext);

  useTokenRefreshInterceptor();

  // Clear the users token and context
  const clearUser = useCallback(() => {
    OpenAPI.TOKEN = undefined;
    if (setPersonAuthDetails && setPerson) {
      setPersonAuthDetails(undefined);
      setPerson(undefined);
    }
  }, [setPersonAuthDetails, setPerson]);

  // When the current auth token stored in context changes, add it to the
  // API config
  useEffectAfterMount(() => {
    if (auth.user?.access_token) {
      const decodedToken = jwtDecode<{
        person_id: string;
        person_type: string;
        ref: string;
      }>(auth.user.access_token);

      const id = decodedToken.person_id;
      const isGuestAccount = decodedToken.person_type === 'Guest';

      if (id && setPersonAuthDetails) {
        setPersonAuthDetails({
          id,
          isGuest: isGuestAccount,
          isFullAccount: !isGuestAccount,
        });
      }

      OpenAPI.TOKEN = auth.user.access_token;
    }
  }, [auth.user]);

  // If the user is ever logged into a different referrer, we want to log them out
  useEffect(() => {
    if (auth.user) {
      const { ten: tenant } = jwtDecode<{
        ten: string;
      }>(auth.user.access_token);

      if (tenant !== referrer.identityProviderName) {
        auth.removeUser();
        clearUser();
      }
    }
  }, [auth, clearUser, referrer]);

  // If the user is unloaded, clear their stored details. This can happen if
  // the guest account is removed, or if we've failed to refresh the token
  // during a 401 interception.
  useEffect(
    () =>
      auth.events.addUserUnloaded(() => {
        clearUser();
      }),
    [auth.events, clearUser]
  );

  // If the token can't be automatically refreshed, and expires
  // attempt a refresh and then restart the silent renew.
  useEffect(
    () =>
      auth.events.addAccessTokenExpired(() => {
        auth.signinSilent().then(() => {
          auth.stopSilentRenew();
          auth.startSilentRenew();
        });
      }),
    [auth.events, auth]
  );

  // Sign out guest accounts on refresh
  useSiteUnloadEffect(() => {
    if (
      isGuest &&
      !location.pathname.includes(QuoteJourneyRoutes.Payment) &&
      !location.pathname.includes(ExtendPolicyRoutes.Payment)
    ) {
      auth.removeUser();
      clearUser();
    }
  });

  return <>{children}</>;
};

export default AuthEventsHandler;
