import { Icon } from '@dayinsure/components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  uppercaseRegex,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
} from '../../../constants/regex';

type PasswordValidationComponentProps = {
  password: string;
  confirmPassword: string;
  variant?: 'default' | 'dialog';
};

const PasswordValidationComponent = ({
  password,
  confirmPassword,
  variant = 'default',
}: PasswordValidationComponentProps) => {
  const { t } = useTranslation('common');

  const textColour = clsx({
    'text-main-content-1': variant === 'default',
    'text-popup-content-1': variant === 'dialog',
  });

  const tickColour = clsx({
    'text-main-content-1': variant === 'default',
    'text-popup-content-1': variant === 'dialog',
  });

  const crossColour = clsx({
    'text-main-content-3': variant === 'default',
    'opacity-10 text-popup-content-1': variant === 'dialog',
  });

  return (
    <div className={clsx('flex flex-col space-y-1 font-lato text-sm', textColour)}>
      <div className="inline-flex items-center space-x-2">
        {password.length >= 8 ? (
          <Icon name="tick" size="0.75rem" className={tickColour} />
        ) : (
          <Icon name="cross" size="0.75rem" className={crossColour} />
        )}
        <p>{t('password_validation.min_chars')}</p>
      </div>

      <div className="inline-flex items-center space-x-2">
        {uppercaseRegex.test(password) ? (
          <Icon name="tick" size="0.75rem" className={tickColour} />
        ) : (
          <Icon name="cross" size="0.75rem" className={crossColour} />
        )}
        <p>{t('password_validation.uppercase')}</p>
      </div>

      <div className="inline-flex items-center space-x-2">
        {lowercaseRegex.test(password) ? (
          <Icon name="tick" size="0.75rem" className={tickColour} />
        ) : (
          <Icon name="cross" size="0.75rem" className={crossColour} />
        )}
        <p>{t('password_validation.lowercase')}</p>
      </div>

      <div className="inline-flex items-center space-x-2">
        {numberRegex.test(password) ? (
          <Icon name="tick" size="0.75rem" className={tickColour} />
        ) : (
          <Icon name="cross" size="0.75rem" className={crossColour} />
        )}
        <p>{t('password_validation.number')}</p>
      </div>

      <div className="inline-flex items-center space-x-2">
        {symbolRegex.test(password) ? (
          <Icon name="tick" size="0.75rem" className={tickColour} />
        ) : (
          <Icon name="cross" size="0.75rem" className={crossColour} />
        )}
        <p>{t('password_validation.symbol')}</p>
      </div>

      <div className="inline-flex items-center space-x-2">
        {password === confirmPassword && password ? (
          <Icon name="tick" size="0.75rem" className={tickColour} />
        ) : (
          <Icon name="cross" size="0.75rem" className={crossColour} />
        )}
        <p>{t('password_validation.password_match')}</p>
      </div>
    </div>
  );
};

export default PasswordValidationComponent;
