import clsx from 'clsx';
import { ReactNode } from 'react';
import Icon, { IconNames } from '../Icon/Icon';

type LabelProps = {
  /** The label text */
  name: ReactNode;
  /** The icon to display next to the label */
  icon?: IconNames;
  /** The id of the input this label is related to */
  htmlFor: string;
  /** Disabled state */
  disabled?: boolean;
  /** Classes for positioning the label */
  className?: string;
  /** id used for testing */
  testId?: string;
};

const Label = ({ name, icon, htmlFor, disabled, className, testId }: LabelProps) => (
  <label
    htmlFor={htmlFor}
    data-testid={testId}
    className={clsx(
      'inline-flex gap-2 items-center leading-5 md:gap-3 text-main-content-1',
      {
        'opacity-40': disabled,
      },
      className
    )}
  >
    {icon && <Icon name={icon} />}
    {name}
  </label>
);

export default Label;
