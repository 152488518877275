import { Card, Icon, LoadingSpinner } from '@dayinsure/components';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DayInsureAPI, OpenAPI, DocumentType } from '../../../api';
import { GetPolicyDocumentsResponse } from '../../../types/GetPolicyDocumentResponse';
import GlobalErrorContext from '../../../contexts/GlobalErrorContext/GlobalErrorContext';
import usePolicyDocsListQuery from '../../../hooks/queries/usePolicyDocsListQuery';
import { onEnterKey } from '../../../types/events';
import SendDocumentDialog from './SendDocumentDialog';

interface PolicyDocumentsList {
  product: string | undefined;
  policyId: string | undefined;
}

const PolicyDocumentsList: React.FC<PolicyDocumentsList> = ({ policyId, product }) => {
  const { t } = useTranslation('policy');
  const [docLoading, setDocLoading] = useState<DocumentType[]>([]);
  const [sendDocumentsModalOpen, setSendDocumentsModalOpen] = useState<boolean>(false);

  const api = new DayInsureAPI(OpenAPI);

  const { setGlobalError } = useContext(GlobalErrorContext);

  const {
    data: policyDocsList,
    isLoading: policyDocsListLoading,
    error: policyDocsListError,
  } = usePolicyDocsListQuery(policyId);

  const productName =
    product === 'ShortTermMotor'
      ? t('policy_info.short_term_motor')
      : t('policy_info.learner_motor');
  const productDocumentsHeading = t('policy_info.documents_header', {
    product: productName,
  });

  const downloadDocumentFromLink = (fileURL: string, fileName: string) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.setAttribute('target', '_blank');
    a.href = fileURL;
    a.download = fileName;
    a.click();
    document.body.removeChild(a);
  };

  const openDocument = async (docType: DocumentType) => {
    setDocLoading([...docLoading, docType]);
    const base64String = await api.policy
      .getApiV1PolicyDocument1(policyId || '', docType || '')
      .then(response => {
        const doc = response.detail as GetPolicyDocumentsResponse;
        return { content: doc.base64Content, name: doc?.displayName };
      })
      .catch(e => {
        setGlobalError(e);
      });

    const byteString = atob(base64String?.content ?? '');
    const byteNumbers = new Array(byteString.length);

    for (let i = 0; i < byteString.length; i += 1) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/pdf;base64' });
    const fileURL = URL.createObjectURL(file);
    return downloadDocumentFromLink(fileURL, base64String?.name || '');
  };

  if (policyDocsList?.detail?.length) {
    return (
      <>
        <Card paddingLevel="small" classNames="bg-content-background-prominent w-full">
          <h2 className="mb-4 text-lg lg:mb-6">{productDocumentsHeading}</h2>

          <p className="mb-4 font-raleway lg:mb-6">
            {t('policy_info.document_download_message')}
          </p>

          {policyDocsList &&
            !policyDocsListError &&
            policyDocsList?.detail?.map(doc => (
              <span
                key={doc.type}
                tabIndex={-1}
                role="link"
                onClick={() => {
                  if (doc.type) {
                    openDocument(doc.type).finally(() => {
                      setDocLoading(docLoading.filter(docType => docType !== doc.type));
                    });
                  }
                }}
                onKeyDown={onEnterKey(() => {
                  if (doc.type) {
                    openDocument(doc.type).finally(() => {
                      setDocLoading(docLoading.filter(docType => docType !== doc.type));
                    });
                  }
                })}
                className={clsx(
                  'flex gap-2 items-center mt-4 mb-2 text-base font-semibold leading-[19px] cursor-pointer link'
                )}
              >
                <LoadingSpinner
                  size="tiny"
                  className={clsx('absolute', {
                    invisible: doc.type && !docLoading.includes(doc.type),
                  })}
                />
                <Icon
                  name="download"
                  className={clsx({
                    invisible: doc.type && docLoading.includes(doc.type),
                  })}
                />
                {doc.displayName}
              </span>
            ))}
          {policyDocsList.detail.find(x => x.sentByPost !== true) && (
            <span
              onClick={() => setSendDocumentsModalOpen(true)}
              onKeyDown={() => setSendDocumentsModalOpen(true)}
              tabIndex={-1}
              role="link"
              className={clsx(
                'flex gap-2 items-center mt-6 text-sm font-semibold cursor-pointer'
              )}
            >
              <Icon name="email" />
              {t('policy_info.send_documents')}
            </span>
          )}
          {policyDocsListLoading && !policyDocsList && (
            <LoadingSpinner size="small" centered />
          )}
          {policyDocsListError && (
            <span className="text-sm">{t('policy_info.docs_error')}</span>
          )}
        </Card>
        <SendDocumentDialog
          policyId={policyId as string}
          documents={policyDocsList}
          open={sendDocumentsModalOpen}
          onClose={() => setSendDocumentsModalOpen(false)}
        />
      </>
    );
  }

  // if no documents return an empty fragment
  return <></>;
};

export default PolicyDocumentsList;
