/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import { usePagination, DOTS } from '../../../helpers/pagination/usePagination';
import Icon from '../../DataDisplay/Icon/Icon';

type PaginationComponentProps = {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
};

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
}: PaginationComponentProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage !== lastPage) onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage !== 1) onPageChange(currentPage - 1);
  };

  return (
    <div className="flex justify-center py-10 w-full md:mt-8">
      <div className="flex justify-center items-center mx-2 w-[32px] h-[32px]">
        <button
          type="button"
          aria-label="Previous page"
          id="pagination-prev-screen-btn"
          className="px-1 text-main-content-1"
          onClick={onPrevious}
        >
          <Icon name="chevron-left" size="0.75rem" />
        </button>
      </div>
      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return (
            <span
              key={idx}
              className="flex justify-center items-center mx-2 w-8 h-8 text-sm text-main-content-1"
            >
              ...
            </span>
          );
        }
        return (
          <div
            key={idx}
            className={clsx('flex justify-center items-center mx-2 w-[32px] h-[32px]', {
              'p-2 rounded-full bg-selector-active text-selector-active-text':
                pageNumber === currentPage,
            })}
          >
            <button
              type="button"
              id={`pagination-screen-${pageNumber}`}
              className="px-1 text-sm"
              onClick={() => onPageChange(pageNumber as number)}
            >
              {pageNumber}
            </button>
          </div>
        );
      })}
      <div className="flex justify-center items-center mx-2 w-[32px] h-[32px]">
        <button
          type="button"
          aria-label="Next page"
          id="pagination-next-screen-btn"
          className="px-1 text-sm text-main-content-1"
          onClick={onNext}
        >
          <Icon name="chevron-right" size="0.75rem" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
