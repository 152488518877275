import { LoadingSpinner, Transition } from '@dayinsure/components';
import clsx from 'clsx';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductName, VehicleType } from '../../../../api';
import {
  learnerSmall,
  learnerMedium,
  learnerLarge,
  motorhomeSmall,
  motorhomeMedium,
  motorhomeLarge,
  vanSmall,
  vanMedium,
  vanLarge,
  carSmall,
  carMedium,
  carLarge,
} from '../../../../assets/vehicles';
import generateSrcSet from '../../../../helpers/images/generateSrcSet';

type FetchingQuoteLoaderProps = {
  show: boolean;
  product: ProductName;
  vehicleType: VehicleType;
};

const MINIMUM_DURATION = 2500;

const FetchingQuoteLoader = ({
  show,
  product,
  vehicleType,
}: FetchingQuoteLoaderProps) => {
  const { t } = useTranslation('quotes');
  const [minDurationReached, setMinDurationReached] = useState(true);

  useEffect(() => {
    if (show) {
      document.getElementById('scroll-container')?.scroll({ top: 0, behavior: 'smooth' });
      setMinDurationReached(false);
      setTimeout(() => {
        setMinDurationReached(true);
      }, MINIMUM_DURATION);
    }
  }, [show]);

  const loadingImage = useMemo(() => {
    if (product !== ProductName.SHORT_TERM_MOTOR) {
      return (
        <img
          id="fetching-learner-quote-image"
          src={learnerSmall}
          srcSet={generateSrcSet(learnerSmall, learnerMedium, learnerLarge)}
          className={clsx('mb-8 w-[240px] h-[200px]')}
          alt=""
        />
      );
    }

    if (vehicleType === VehicleType.MOTORHOME) {
      return (
        <img
          id="fetching-motorhome-quote-image"
          src={motorhomeSmall}
          srcSet={generateSrcSet(motorhomeSmall, motorhomeMedium, motorhomeLarge)}
          className={clsx('mb-8 w-[240px] h-[233px]')}
          alt=""
        />
      );
    }

    if (vehicleType === VehicleType.VAN_LCV) {
      return (
        <img
          id="fetching-van-quote-image"
          src={vanSmall}
          srcSet={generateSrcSet(vanSmall, vanMedium, vanLarge)}
          className={clsx('mb-8 w-[240px] h-[183px]')}
          alt=""
        />
      );
    }

    return (
      <img
        id="fetching-car-quote-image"
        src={carSmall}
        srcSet={generateSrcSet(carSmall, carMedium, carLarge)}
        className={clsx('mb-8 w-[240px] h-[166px]')}
        alt=""
      />
    );
  }, [product, vehicleType]);

  return (
    <Transition
      show={show || !minDurationReached}
      enter="transition-opacity duration-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as={Fragment}
    >
      <div
        className={clsx(
          'flex absolute top-0 left-0 z-30 flex-col items-center px-6 pt-6 w-full h-full text-center md:pt-12',
          'bg-gradient-to-br from-page-background-from to-page-background-to'
        )}
      >
        {loadingImage}
        <span
          className="mb-8 text-lg text-main-content-1"
          id="fetching-quote-loader-text"
        >
          {product === ProductName.SHORT_TERM_MOTOR
            ? t('fetching_quote.short_term_motor')
            : t('fetching_quote.learner_motor')}
        </span>
        <LoadingSpinner />
      </div>
    </Transition>
  );
};

export default FetchingQuoteLoader;
