import { useQuery } from 'react-query';
import {
  ApiError,
  DayInsureAPI,
  MarketingPreferenceListIAPIResult,
  OpenAPI,
} from '../../api';

const useMarketingPreferenceQuery = (id: string | undefined | null) => {
  const getMarketingPreferences = () => {
    const api = new DayInsureAPI(OpenAPI);

    return api.person.getApiV1PersonMarketingPreferences(id || '');
  };

  return useQuery<MarketingPreferenceListIAPIResult, ApiError>(
    ['marketing-preferences', id],
    getMarketingPreferences,
    {
      retry: (count, error: ApiError) => error.status !== 404 && count < 3,
      enabled: id !== undefined,
    }
  );
};

export default useMarketingPreferenceQuery;
