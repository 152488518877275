import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ApiError, DayInsureAPI, OpenAPI } from '../../api';
import GlobalErrorContext from '../../contexts/GlobalErrorContext/GlobalErrorContext';
import query from './queryKeys';

const usePersonSummaryQuery = (id: string | undefined) => {
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getPerson = () => {
    const api = new DayInsureAPI(OpenAPI);

    return api.person.getApiV1Person(id || '');
  };

  return useQuery(query.person, getPerson, {
    retry: (count, error: ApiError) => error.status !== 404 && count < 3,
    enabled: id !== undefined,
    onError: err => {
      setGlobalError(err);
    },
  });
};

export default usePersonSummaryQuery;
