import { ApiError } from '../api';

export const isApiError = (error: unknown): error is ApiError => {
  const mappedError = error as ApiError;
  return (
    mappedError?.url !== undefined &&
    mappedError?.status !== undefined &&
    mappedError?.statusText !== undefined &&
    mappedError?.body !== undefined
  );
};

interface ApiErrorWithMessage extends ApiError {
  body: {
    messages: { message: string }[];
  };
}

export const isApiErrorWithMessage = (error: unknown): error is ApiErrorWithMessage => {
  if (isApiError(error)) {
    return error.body.messages?.[0].message !== undefined;
  }

  return false;
};
