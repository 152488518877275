/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebDocumentIAPIResult } from '../models/WebDocumentIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DocumentService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns web document matching the given query
   * @param documentType
   * @param underwriter
   * @param product
   * @param referrer
   * @param assetType
   * @returns WebDocumentIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Document(
    documentType: string,
    underwriter?: string,
    product?: string,
    referrer?: string,
    assetType?: string,
  ): CancelablePromise<WebDocumentIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/document/{documentType}',
      path: {
        'documentType': documentType,
      },
      query: {
        'underwriter': underwriter,
        'product': product,
        'referrer': referrer,
        'assetType': assetType,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

}