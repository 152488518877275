/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptanceCriteriaResponseListIAPIResult } from '../models/AcceptanceCriteriaResponseListIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { PostAcceptanceCriteriaAgreementRequest } from '../models/PostAcceptanceCriteriaAgreementRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AcceptanceCriteriaService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Authorized - Gets the Acceptance Criteria for the given quote
   * @param quoteId
   * @returns AcceptanceCriteriaResponseListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1AcceptanceCriteria(
    quoteId?: string,
  ): CancelablePromise<AcceptanceCriteriaResponseListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/acceptance-criteria',
      query: {
        'quoteId': quoteId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Creates a set of acceptance criteria agreements
   * @param requestBody
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1AcceptanceCriteriaAgreement(
    requestBody?: PostAcceptanceCriteriaAgreementRequest,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/acceptance-criteria-agreement',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}