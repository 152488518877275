import { useSearchParams } from 'react-router-dom';
import { getReferrerNameFromOidcState } from '../../../hooks/useReferrerFetcher';
import NavigateWithReferrer from '../../Common/Links/NavigateWithReferrer';

const Logout = () => {
  const [searchParams] = useSearchParams();
  const oidcStateParam = searchParams.get('state');

  if (oidcStateParam) {
    const referrerName = getReferrerNameFromOidcState(oidcStateParam);
    return (
      <NavigateWithReferrer
        to={{ pathname: '/quote', search: `referrer=${referrerName}` }}
      />
    );
  }

  return <NavigateWithReferrer to={{ pathname: '/quote' }} />;
};

export default Logout;
