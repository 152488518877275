export const fullCountdown = (endDate: string) => {
  const end = new Date(endDate);
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  let countdownString = '';

  const now = new Date();
  const distance = end.getTime() - now.getTime();
  if (distance < 0) {
    return 0;
  }
  const days = Math.floor(distance / day);
  const hours = Math.floor((distance % day) / hour);
  const minutes = Math.floor((distance % hour) / minute);

  countdownString = `${days}d `;
  countdownString += `${hours}h `;
  countdownString += `${minutes}m `;
  return countdownString;
};

export const daysCountdown = (endDate: string) => {
  const end = new Date(endDate);
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;

  const now = new Date();
  const distance = end.getTime() - now.getTime();
  const days = Math.floor(distance / day);

  return days;
};
