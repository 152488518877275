import { Card, Icon } from '@dayinsure/components';
import clsx from 'clsx';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UnderWriterName } from '../../../api';
import { AVIVA_CLAIMS_NUMBER, MULSANNE_CLAIMS_NUMBER } from '../../../constants/contact';

type ClaimsNumberCardProps = {
  underwriter: UnderWriterName;
  className?: string;
};

const ClaimsNumberCard = ({ underwriter, className }: ClaimsNumberCardProps) => {
  const { t } = useTranslation('policy');
  const claimsNumber =
    underwriter === UnderWriterName.AVIVA ? AVIVA_CLAIMS_NUMBER : MULSANNE_CLAIMS_NUMBER;

  return (
    <Card paddingLevel="small" classNames={clsx('flex items-center', className)}>
      <Icon className="text-main-content-1" size="2rem" name="phone" />
      <div className="ml-6 text-main-content-1" id="insurance-claim-card">
        <Trans
          i18nKey="claim_contact.message"
          t={t}
          values={{
            underwriter,
            number: claimsNumber,
          }}
          components={[
            <a
              id="insurance-claim-contact-number"
              type="button"
              href={`tel:${claimsNumber}`}
              className="appearance-none link"
            >
              {claimsNumber}
            </a>,
          ]}
        />
      </div>
    </Card>
  );
};

export default ClaimsNumberCard;
