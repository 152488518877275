import clsx from 'clsx';
import { ReactNode } from 'react';
import Icon, { IconNames } from '../../Icon/Icon';

export type AsideCardItemProps = {
  children?: string;
  className?: string;
  icon?: IconNames;
  id?: string;
  link?: ReactNode;
  title: string;
};

export const AsideCardItem = ({
  children,
  className,
  icon,
  id,
  link,
  title,
}: AsideCardItemProps) => (
  <>
    <div id={id} className={clsx('flex justify-between mt-4 mb-2', className)}>
      <span className="flex gap-2 items-center text-sm leading-3 text-white/60">
        {icon && <Icon name={icon} />}
        {title}
      </span>
      {link}
    </div>
    {children && <span id={`${id}-content`}>{children}</span>}
  </>
);
