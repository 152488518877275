import React from 'react';

type GlobalErrorContextType = {
  error: boolean;
  title: string | undefined;
  message: string | undefined;
  cta: string | undefined;
  ctaId: string | undefined;
  onClose: (() => void) | undefined;
  /**
   * You can either pass in your error object and this will attempt to find an error
   * message inside the object, or you can specify exact values via the second arg.
   * if a second arg is provided, the first will be ignored
   */
  setGlobalError: (
    errorObject: unknown,
    values?: {
      title?: string;
      message?: string;
      cta?: string;
      ctaId?: string;
      onClose?: () => void;
    }
  ) => void;
  clearGlobalError: () => void;
};

const GlobalErrorContext = React.createContext<GlobalErrorContextType>({
  error: false,
  title: undefined,
  message: undefined,
  cta: undefined,
  ctaId: undefined,
  onClose: undefined,
  setGlobalError: () => {},
  clearGlobalError: () => {},
});

export const GlobalErrorProvider = GlobalErrorContext.Provider;

export default GlobalErrorContext;
