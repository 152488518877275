import React from 'react';

type Props = {
  title: string;
  value: React.ReactNode;
  testId: string;
  addDivider?: boolean;
};

const BreakdownItem = ({ title, value, addDivider, testId }: Props) => (
  <div key={title} className="flex flex-col items-center w-full">
    <span className="px-4 mb-2 text-sm text-popup-content-1">{title}</span>
    <span className="px-4 leading-5 text-popup-title" id={testId}>
      {value}
    </span>
    {addDivider && <hr className="my-4 w-full border-y border-popup-background" />}
  </div>
);

export default BreakdownItem;
