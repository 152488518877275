import { Avatar, Drawer, Icon } from '@dayinsure/components';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import LinkWithReferrer from '../../Common/Links/LinkWithReferrer';

const AccountDrawer = (): JSX.Element => {
  const { t } = useTranslation('header');
  const auth = useAuth();

  return (
    <Drawer
      trigger={
        <button
          id="account-user-avatar-btn-drawer"
          type="button"
          aria-label={t('account.open')}
        >
          <Avatar />
        </button>
      }
    >
      <div className="w-full md:w-80 text-menu-content-1">
        <div className="flex justify-between items-center py-4 px-6">
          <span className="text-lg leading-7">{t('account.title')}</span>
          <Drawer.Close
            id="account-menu-close-btn-drawer"
            aria-label={t('account.close')}
          >
            <Icon name="cross" />
          </Drawer.Close>
        </div>
        <hr className="text-menu-content-2" />
        <div className="py-8 px-6 space-y-8">
          <Drawer.Close
            as={LinkWithReferrer}
            id="account-menu-account-details-btn-drawer"
            to="/account/details"
            className="link-with-icon"
          >
            <Icon name="user" />
            {t('account.account_details')}
          </Drawer.Close>
          <Drawer.Close
            as={LinkWithReferrer}
            id="account-menu-contact-details-btn-drawer"
            to="/account/contact-details"
            className="link-with-icon"
          >
            <Icon name="email" />
            {t('account.contact_details')}
          </Drawer.Close>
          <Drawer.Close
            as={LinkWithReferrer}
            id="account-menu-saved-card-btn-drawer"
            to="/account/saved-cards"
            className="link-with-icon"
          >
            <Icon name="cards" />
            {t('account.saved_cards')}
          </Drawer.Close>
          <Drawer.Close
            as={LinkWithReferrer}
            id="account-menu-password-btn-drawer"
            to="/account/password"
            className="link-with-icon"
          >
            <Icon name="lock" />
            {t('account.password')}
          </Drawer.Close>
          <Drawer.Close
            type="button"
            id="account-menu-logout-btn-drawer"
            className="link-with-icon"
            onClick={() => {
              auth.signoutRedirect({
                id_token_hint: auth.user?.id_token,
                post_logout_redirect_uri: `${window.origin}/logout`,
              });
            }}
          >
            <Icon name="logout" />
            {t('account.logout')}
          </Drawer.Close>
        </div>
      </div>
    </Drawer>
  );
};

export default AccountDrawer;
