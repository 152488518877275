import { Card, LinearProgressBar } from '@dayinsure/components';
import { useTranslation } from 'react-i18next';
import { GetPolicyResponseIAPIResult, PolicyState } from '../../../../api';
import { getContentFromState, getLabelFromState } from './utils';

interface Props {
  policyData?: GetPolicyResponseIAPIResult;
}

const PolicyStateProgressBar = ({ policyData }: Props) => {
  const { t } = useTranslation('policy');

  const policyState = policyData?.detail?.policy?.state;
  const progressPercentage =
    policyState === PolicyState.CANCELLATION_REQUESTED
      ? 80
      : policyData?.detail?.policy?.progressPercentage;

  return (
    <Card paddingLevel="small" classNames="bg-content-background-prominent w-full">
      <h2 className="text-lg lg:mb-2">{t('policy_info.policy')}</h2>
      <span className="flex mt-6 mb-2 leading-[120%]">
        {getLabelFromState(t, policyState)}
      </span>
      <LinearProgressBar
        id="policy-info-progress-bar"
        progressPercentage={progressPercentage}
      />
      <span className="flex mt-2 text-sm leading-[120%] text-main-content-2">
        {getContentFromState(t, policyState, policyData)}
      </span>
    </Card>
  );
};

export { PolicyStateProgressBar };
