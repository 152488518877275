import { Dialog, Card, Button, RadioSelect } from '@dayinsure/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LicenceType } from '../../../api';

type LicenceTypeDialogProps = {
  open: boolean;
  onClose: () => void;
  licenceTypeChosen: (type: LicenceType) => void;
};

const LicenceTypeDialog = ({
  open,
  onClose,
  licenceTypeChosen,
}: LicenceTypeDialogProps) => {
  const { t } = useTranslation('quotes');
  const [selectedLicenceType, setSelectedLicenceType] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(undefined);
  const [error, setError] = useState(false);

  const handleClose = () => {
    if (selectedLicenceType) {
      licenceTypeChosen(selectedLicenceType.id as LicenceType);
      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('contact_details.licence_type_dialog.title')}
    >
      <Card
        classNames="!bg-popup-background flex items-center gap-4 md:gap-6 text-left mb-6 md:mb-8"
        paddingLevel="small"
      >
        <span>{t('contact_details.licence_type_dialog.card')}</span>
      </Card>
      <RadioSelect
        id="learner-driver-dialog-option"
        name="learner-driver-dialog-option"
        variant="secondary"
        value={selectedLicenceType}
        options={[
          {
            id: LicenceType.PROVISIONAL,
            name: t('contact_details.licence_type_dialog.provisional'),
            testId: 'learner-driver-dialog-option-provisional',
          },
          {
            id: LicenceType.FULL,
            name: t('contact_details.licence_type_dialog.full'),
            testId: 'learner-driver-dialog-option-full',
          },
        ]}
        onChange={val => {
          setError(false);
          setSelectedLicenceType(val);
        }}
        error={error}
      />
      <Button
        id="learner-driver-dialog-continue"
        fullWidth
        onClick={handleClose}
        text={t('contact_details.licence_type_dialog.continue')}
        icon={{ position: 'right', name: 'arrow-right' }}
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};

export default LicenceTypeDialog;
