import { LoadingSpinner } from '@dayinsure/components';
import clsx from 'clsx';
import React, { useState } from 'react';
import useEffectOnce from '../../../hooks/useEffectOnce';

const LazyLoaderSpinner = () => {
  const [showLoader, setShowLoader] = useState(false);

  useEffectOnce(() => {
    setTimeout(() => {
      setShowLoader(true);
    }, 250);
  });

  return (
    <div
      className={clsx(
        'flex justify-center items-center w-full h-full transition-opacity',
        { 'opacity-0': !showLoader }
      )}
    >
      <LoadingSpinner className="mt-8" />
    </div>
  );
};

export default LazyLoaderSpinner;
