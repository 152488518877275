import { FieldError, Icon } from '@dayinsure/components';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import gbflag from './image_gbflag.svg';
import { onEnterKey } from '../../../../types/events';

/** This is just a styled input with some mandatory props */
export type VehicleRegProps = {
  /** ID of the input field. Used to match with label */
  id: string;
  /** Name for the field. Used to link up to formik */
  name: string;
} & Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'name'>;

const VehicleReg = ({ ...inputProps }: VehicleRegProps): JSX.Element => {
  const [field, meta] = useField(inputProps);
  const { t } = useTranslation('quotes');

  const clearField = () => {
    field.onChange({ target: { value: '', name: field.name } });
  };

  return (
    <div className="relative">
      <div className="flex">
        <div
          className={`flex flex-col justify-center items-center w-10 h-16 bg-[#003287] rounded-l-lg lg:w-12 lg:h-[4.5rem] ${
            meta.error && meta.touched
              ? 'border-error-border border-y-2 border-l-2'
              : 'border-y-2 border-l-2 box-border border-reg-plate'
          }`}
        >
          <img
            src={gbflag}
            alt={t('vehicle.registration.flag')}
            width="16px"
            height="12px"
          />
          <p className="pt-1 text-sm text-white lg:text-md">
            {t('vehicle.registration.gb')}
          </p>
        </div>
        <div
          className={`flex relative items-center overflow-hidden w-full h-16 bg-[#FFC850] rounded-r-lg lg:h-[4.5rem] ${
            meta.error && meta.touched
              ? 'border-error-border border-y-2 border-r-2'
              : 'border-y-2 border-r-2 box-border border-reg-plate'
          }`}
        >
          <input
            {...inputProps}
            {...field}
            onChange={e => {
              e.target.value = e.target.value.toUpperCase().replace(' ', '');
              field.onChange(e);
            }}
            id={inputProps.id}
            type="text"
            maxLength={8}
            autoComplete="off"
            className="flex focus:placeholder:invisible items-center mr-8 w-full text-[1.25rem] font-semibold text-center bg-transparent focus:outline-none lg:text-lg  placeholder:text-reg-plate-text text-reg-plate-text"
            placeholder={t('vehicle.registration.placeholder')}
            aria-invalid={meta.touched && meta.error ? 'true' : 'false'}
            aria-errormessage={`${inputProps.id}-error`}
          />
          {field.value && (
            <button
              className="flex absolute right-6 z-10 p-2"
              aria-label={t('vehicle.registration.clear')}
              type="button"
              onKeyDown={onEnterKey(clearField)}
              onMouseDown={clearField}
            >
              <Icon className="text-reg-plate-text" name="cross" />
            </button>
          )}
        </div>
      </div>

      {meta.touched && meta.error && (
        <FieldError
          id={`${inputProps.id}-error`}
          data-testid="registrationError"
          error={meta.error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};

export default VehicleReg;
