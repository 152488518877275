import { Listbox as HeadlessListbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { forwardRef, Fragment, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import Icon from '../../DataDisplay/Icon/Icon';

// TODO: This component should have a loading state for when results are being fetched.
// We do not currently have a design for this.

export type ListboxOption = {
  /** ID of the option */
  id: string;
  /** Display name */
  name: string;
  /** Disabled */
  disabled?: boolean;
  /** postKey */
  postKey?: string;
};

export type ListboxProps = {
  /** ID of the input field. Used to match with label */
  id: string;
  /** Name of the field. Used to track within formik  */
  name: string;
  /** The options available in the listbox */
  options: ListboxOption[];
  /** The current value. Must match one of the options. Can be undefined */
  value: ListboxOption | undefined;
  /** The event which will trigger when an option is selected */
  onChange: (option: ListboxOption) => void;
  /** The event which will trigger when the listbox is blurred */
  onBlur?: () => void;
  /** The message to display when the value is undefined */
  unselectedMessage: string;
  /** The message to display when there are no options */
  noOptionsMessage: string;
  /** Error state */
  error?: boolean;
  /** Disabled state */
  disabled?: boolean;
  /**  Whether to use the primary or dialog colour scheme */
  variant?: 'primary' | 'dialog' | 'annual';
  /** id used for testing */
  testId?: string;
};

const Listbox = forwardRef<HTMLButtonElement, ListboxProps>(
  (
    {
      id,
      name,
      options,
      value,
      onChange,
      onBlur,
      unselectedMessage,
      noOptionsMessage,
      error,
      disabled,
      variant = 'primary',
      testId,
    }: ListboxProps,
    forwardedRef
  ) => {
    const popperElRef = useRef(null);
    const [listboxButtonlement, setListboxButtonElement] =
      useState<HTMLDivElement | null>(null);
    const [listboxDropdownElement, setListboxDropdownElement] =
      useState<HTMLDivElement | null>(null);
    const {
      styles,
      attributes: { popper },
    } = usePopper(listboxButtonlement, listboxDropdownElement, {
      placement: 'bottom',
    });

    return (
      <HeadlessListbox name={name} value={value} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <div ref={setListboxButtonElement} className="relative w-full">
            <HeadlessListbox.Button
              id={id}
              data-testid={testId}
              className={clsx(
                'justify-between border-2 transition-all text-main-content-1 field-base',
                {
                  'border-error-border': !open && error,
                  'border-main-content-3': !open && !error && variant === 'primary',
                  'border-popup-selector-border': !open && !error && variant === 'dialog',
                  'rounded-lg': !open,
                  'rounded-t-lg': open && popper?.['data-popper-placement'] === 'bottom',
                  'rounded-b-lg': open && popper?.['data-popper-placement'] === 'top',
                  'border-main-content-1': open && variant === 'primary',
                  'border-popup-content-2': open && variant === 'dialog',
                  'opacity-40': disabled,
                }
              )}
              ref={forwardedRef}
            >
              <span
                className={clsx('block w-[220px] text-left truncate xs:w-max ', {
                  'text-popup-content-1': variant === 'dialog',
                })}
              >
                {value ? value.name : unselectedMessage}
              </span>
              <Icon
                name="chevron-down"
                className={clsx(
                  'transition-transform',
                  { 'rotate-180': open },
                  { 'text-popup-secondary-cta-text': variant === 'dialog' },
                  { 'text-cta-secondary-text': variant === 'annual' }
                )}
              />
            </HeadlessListbox.Button>
            <div
              ref={popperElRef}
              className="z-20 w-full"
              style={styles.popper}
              {...popper}
            >
              <Transition
                show={open}
                as={Fragment}
                enter="transition-opacity ease-in duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                beforeEnter={() => setListboxDropdownElement(popperElRef.current)}
                afterLeave={() => setListboxDropdownElement(null)}
              >
                <HeadlessListbox.Options
                  data-testid={`${id}-options`}
                  className={clsx(
                    'overflow-auto w-full max-h-72 bg-white border-2 focus:outline-none lg:max-h-80',
                    {
                      'rounded-b-lg border-t-0':
                        popper?.['data-popper-placement'] === 'bottom',
                    },
                    {
                      'rounded-t-lg border-b-0':
                        popper?.['data-popper-placement'] === 'top',
                    },
                    { 'border-main-content-1': variant === 'primary' },
                    { 'border-popup-content-2': variant === 'dialog' },
                    { 'overflow-y-hidden': options.length <= 5 }
                  )}
                  onBlur={onBlur}
                >
                  {options.length ? (
                    options.map(option => (
                      <HeadlessListbox.Option
                        as={Fragment}
                        key={option.id}
                        value={option}
                        disabled={option.disabled}
                      >
                        {({ active }) => (
                          <li
                            className={clsx(
                              'border-y first:border-t-0 last:border-b-0 cursor-pointer text-popup-content-1 border-popup-background field-base h-auto py-4',
                              { 'bg-popup-background': active },
                              { 'opacity-40 cursor-default': option.disabled }
                            )}
                          >
                            <span
                              className={clsx('block truncate whitespace-normal', {
                                'text-popup-content-1': variant === 'dialog',
                              })}
                            >
                              {option.name}
                            </span>
                          </li>
                        )}
                      </HeadlessListbox.Option>
                    ))
                  ) : (
                    <span
                      className={clsx(
                        'border-y first:border-t-0 last:border-b-0 text-popup-content-1 border-popup-background field-base'
                      )}
                    >
                      {noOptionsMessage}
                    </span>
                  )}
                </HeadlessListbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </HeadlessListbox>
    );
  }
);

export default Listbox;
