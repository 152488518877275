import { Transition } from '@headlessui/react';
import React, { useEffect } from 'react';

type ToastProps = {
  /** An id to attach to the toast message */
  id?: string;
  /** The message to display in the toast */
  message: string;
  /** Controls whether or not to show the toast */
  open: boolean;
  /** The duration in ms to show the toast before triggering the onClose callback. Default is 3000. */
  hideDuration?: number;
  /** Triggers when the toast should be closed. */
  onClose: () => void;
};

const Toast = ({ id, message, open, hideDuration = 3000, onClose }: ToastProps) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        onClose();
      }, hideDuration);
    }
  }, [open, onClose, hideDuration]);

  return (
    <Transition
      className="fixed inset-x-0 bottom-6 z-50 p-4 w-screen sm:bottom-8 lg:bottom-12"
      show={open}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      appear
    >
      <div
        id={id}
        aria-label="alert"
        className="p-4 mx-auto w-fit font-lato bg-white rounded-lg border-2 text-popup-content-1 border-main-content-5"
      >
        {message}
      </div>
    </Transition>
  );
};

export default Toast;
