import { Dialog, Card, Listbox, Button, Icon, Toast } from '@dayinsure/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetPolicyDocumentsResponseIListIAPIResult } from '../../../api';
import useSendDocumentMutation from '../../../hooks/mutations/useSendDocumentMutation';
import { isApiError } from '../../../types/typeguards';

type SendDocumentDialogProps = {
  open: boolean;
  onClose: () => void;
  documents: GetPolicyDocumentsResponseIListIAPIResult | undefined | null;
  policyId: string;
};

const SendDocumentDialog = ({
  open,
  onClose,
  documents,
  policyId,
}: SendDocumentDialogProps) => {
  const { t } = useTranslation('policy');
  const [selectedDocument, setSelectedDocument] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(undefined);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState<
    'SendDocument' | 'ConfirmSendDocument' | 'Error'
  >('SendDocument');
  const sendDocumentMutation = useSendDocumentMutation();

  const handleClose = () => {
    onClose();
    setError(false);
  };

  const handleConfirmSubmit = () => {
    setCurrentStep('SendDocument');
  };

  useEffect(() => {
    if (open) {
      setSelectedDocument(undefined);
      setCurrentStep('ConfirmSendDocument');
    }
  }, [open]);

  const handleSubmit = () => {
    if (!selectedDocument) {
      setError(true);
    } else if (documents && documents.detail && selectedDocument) {
      const doc = documents.detail[selectedDocument.id as unknown as number];
      if (doc.type) {
        sendDocumentMutation
          .mutateAsync({ policyId, type: doc.type })
          .then(() => {
            onClose();
            setSuccessToastOpen(true);
          })
          .catch(err => {
            setCurrentStep('Error');
            if (isApiError(err)) {
              setErrorMessage(err.body.messages[0].message);
            } else {
              setErrorMessage('Something went wrong');
            }
          });
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        title={
          // eslint-disable-next-line no-nested-ternary
          currentStep === 'SendDocument'
            ? t('documentsDialog.title')
            : currentStep === 'ConfirmSendDocument'
            ? t('confirmDocumentsDialog.title')
            : t('documentsDialog.error')
        }
      >
        {currentStep === 'SendDocument' && (
          <>
            <Card
              classNames="!bg-popup-background flex items-center text-left mb-6 md:mb-8"
              paddingLevel="small"
            >
              <span>{t('documentsDialog.cardText')}</span>
            </Card>
            {documents && documents.detail && (
              <Listbox
                id="send-documents-list-box"
                testId="send-documents-list-box"
                name="send-documents-dialog-option"
                value={selectedDocument}
                options={documents.detail.map((doc, idx) => {
                  return {
                    id: idx.toString(),
                    name: doc.displayName as string,
                    disabled: doc.sentByPost,
                  };
                })}
                onChange={val => {
                  setError(false);
                  setSelectedDocument(val);
                }}
                error={error}
                unselectedMessage={t('documentsDialog.unselectedMessage')}
                noOptionsMessage={t('documentsDialog.noOptionsMessage')}
                variant="dialog"
              />
            )}
            <Button
              id="send-documents-cancel"
              fullWidth
              onClick={handleClose}
              text={t('documentsDialog.cancel')}
              classNames="mt-6 md:mt-8"
            />
            <Button
              id="send-documents-submit"
              fullWidth
              onClick={handleSubmit}
              text={t('documentsDialog.confirm')}
              classNames="mt-6 md:mt-8"
              variant="dialogSecondary"
              loading={sendDocumentMutation.isLoading}
            />
          </>
        )}
        {currentStep === 'ConfirmSendDocument' && (
          <>
            <Card
              classNames="!bg-popup-background flex items-center gap-4 md:gap-6 text-left mb-6 md:mb-8"
              paddingLevel="small"
            >
              <Icon name="warning" size="2rem" />
              <span>{t('confirmDocumentsDialog.cardText')}</span>
            </Card>
            <Button
              id="confirm-send-documents-cancel"
              fullWidth
              onClick={handleClose}
              text={t('confirmDocumentsDialog.cancel')}
              disabled={sendDocumentMutation.isLoading}
            />
            <Button
              id="confirm-send-documents-submit"
              fullWidth
              onClick={handleConfirmSubmit}
              text={t('confirmDocumentsDialog.confirm')}
              classNames="mt-6 md:mt-8"
              variant="dialogSecondary"
            />
          </>
        )}
        {currentStep === 'Error' && (
          <>
            <Card
              classNames="!bg-popup-background flex items-center gap-4 md:gap-6 text-left"
              paddingLevel="small"
            >
              <Icon name="warning" size="2rem" className="text-popup-link" />
              <span id="dialog-error-message">{errorMessage}</span>
            </Card>
            <Button
              id="send-documents-error-cancel"
              fullWidth
              onClick={onClose}
              text={t('documentsList.cancel')}
              classNames="mt-6 md:mt-8"
            />
          </>
        )}
      </Dialog>
      <Toast
        id="document-sent-toast"
        message={t('confirmDocumentsDialog.success')}
        open={successToastOpen}
        onClose={() => {
          setSuccessToastOpen(false);
        }}
      />
    </>
  );
};

export default SendDocumentDialog;
