/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
/**
 * Omits effect on initial render and runs it only in subsequent renders.
 */
const useEffectAfterMount = (
  effect: React.EffectCallback,
  deps: React.DependencyList
): void => {
  const componentJustMounted = useRef(true);

  useEffect(() => {
    if (!componentJustMounted.current) {
      return effect();
    }
    componentJustMounted.current = false;

    return undefined;
  }, deps);
};

export default useEffectAfterMount;
