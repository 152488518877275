import React, { Fragment, useRef, useState } from 'react';
import { Day, DayPicker, DayPickerProps, StyledComponent } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import en from 'date-fns/locale/en-GB';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import Icon from '../../DataDisplay/Icon/Icon';

const CustomLeftArrow = (props: StyledComponent) => <Icon name="arrow-left" {...props} />;
const CustomRightArrow = (props: StyledComponent) => (
  <Icon name="arrow-right" {...props} />
);

const getDayString = (day: number) => {
  switch (day) {
    case 0:
      return 'S';
    case 1:
      return 'M';
    case 2:
      return 'T';
    case 3:
      return 'W';
    case 4:
      return 'T';
    case 5:
      return 'F';
    case 6:
    default:
      return 'S';
  }
};
const locale = {
  ...en,
  localize: { ...en.localize, day: getDayString || undefined },
} as Locale;

export type DatePickerProps = {
  button: JSX.Element;
  placement?: Placement;
  closeOnClick?: boolean;
} & DayPickerProps;

const DatePicker = ({
  button,
  placement = 'bottom-end',
  closeOnClick,
  ...props
}: DatePickerProps) => {
  const popperElRef = useRef(null);
  const [pickerButtonlement, setPickerButtonElement] = useState<HTMLDivElement | null>(
    null
  );
  const [pickerDropdownElement, setPickerDropdownElement] =
    useState<HTMLDivElement | null>(null);
  const {
    styles,
    attributes: { popper },
  } = usePopper(pickerButtonlement, pickerDropdownElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  return (
    <Popover className="relative">
      {({ open }) => (
        <div ref={setPickerButtonElement}>
          <Popover.Button as={Fragment}>{button}</Popover.Button>
          <div
            ref={popperElRef}
            className={clsx('relative z-10 w-full sm:w-80 lg:w-[26.5rem]')}
            style={styles.popper}
            {...popper}
          >
            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              beforeEnter={() => setPickerDropdownElement(popperElRef.current)}
              afterLeave={() => setPickerDropdownElement(null)}
            >
              <Popover.Panel
                className={clsx(
                  'inset-x-0 z-10 p-6 w-full bg-white rounded-lg border-2 text-popup-content-1 border-main-content-5 datepicker'
                )}
              >
                {({ close }) => (
                  <DayPicker
                    styles={{
                      root: { margin: '0px' },
                      caption: {
                        paddingBottom: '.75rem',
                      },
                      caption_label: {
                        color: 'rgba(var(--popup-title))',
                        fontSize: '1rem',
                        fontWeight: 'normal',
                      },
                      nav: { color: 'rgba(var(--popup-link))' },
                      nav_button_previous: { marginRight: '.5rem' },
                      month: { width: '100%' },
                      head_cell: {
                        paddingBottom: '.5rem',
                        color: 'rgba(var(--popup-content-1))',
                        opacity: '0.4',
                        fontFamily: 'Lato',
                        fontSize: '.75em',
                        fontWeight: 'normal',
                      },
                      table: { maxWidth: '100%', width: '100%' },
                      cell: {
                        paddingTop: '.5rem',
                        color: 'rgba(var(--popup-content-1))',
                        fontFamily: 'Lato',
                        fontSize: '.75em',
                      },
                      button_reset: {
                        width: '2rem',
                        height: '2rem',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      },
                    }}
                    components={{
                      IconLeft: CustomLeftArrow,
                      IconRight: CustomRightArrow,
                      Day,
                    }}
                    weekStartsOn={1}
                    locale={locale}
                    onDayClick={() => {
                      if (closeOnClick) close();
                    }}
                    {...props}
                  />
                )}
              </Popover.Panel>
            </Transition>
          </div>
        </div>
      )}
    </Popover>
  );
};

export default DatePicker;
