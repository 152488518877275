import React from 'react';

type PageContainerProps = {
  children?: JSX.Element | JSX.Element[];
  className?: string;
};

const PageContainer = ({ children, className }: PageContainerProps) => {
  return (
    <div
      className={`px-6 md:px-0 lg:px-6 mx-auto sm:max-w-tablet-page md:min-w-tablet-page lg:max-w-desktop-page ${className}`}
    >
      {children}
    </div>
  );
};

export default PageContainer;
