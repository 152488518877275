import { ReactNode } from 'react';

export type SiteLayoutProps = {
  children: ReactNode;
  header: ReactNode;
  footer: ReactNode;
};

export const SiteLayout = ({ children, header, footer }: SiteLayoutProps) => (
  <div
    id="scroll-container"
    className="flex overflow-auto fixed inset-y-0 flex-col w-screen bg-gradient-to-br text-main-content-1 from-page-background-from to-page-background-to"
  >
    {header}
    <main className="relative grow">{children}</main>
    {footer}
  </div>
);
