import { useMutation, useQueryClient } from 'react-query';
import {
  DayInsureAPI,
  OpenAPI,
  QuoteSummaryResponseIAPIResult,
  UpdateQuoteRequest,
} from '../../api';
import query from '../queries/queryKeys';

type QueryMutationType = {
  id: string;
} & UpdateQuoteRequest;

const useQuoteMutation = (updateDocuments = false) => {
  const queryClient = useQueryClient();

  const updateQuote = ({ id, ...requestBody }: QueryMutationType) => {
    const api = new DayInsureAPI(OpenAPI);

    return api.quote.putApiV1Quote(id, requestBody);
  };

  return useMutation(updateQuote, {
    mutationKey: 'update-quote',
    onSuccess: (data, variables) => {
      queryClient.setQueryData<QuoteSummaryResponseIAPIResult>(
        query.quoteDetails(variables.id),
        quote => {
          return {
            ...quote,
            detail: {
              ...quote?.detail,
              ...data.detail,
            },
          };
        }
      );

      if (updateDocuments) {
        queryClient.invalidateQueries({ queryKey: query.quoteDocuments(variables.id) });
      }
    },
  });
};

export default useQuoteMutation;
