export const UKReg =
  /(^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,4}$)/;
export const NIReg = /(^[A-Z]{3}[1-9]{1,4}$)/;
export const IOMReg =
  /(^(MN|MAN)-[1-9]{1,4}$)|(^MAN-[1-9]{1,3}-[^IQSZ]$)|(^([^AIQSZ]MN-[1-9]{1,3})(-[^IQSZ]{1})?$)|(^[1-9]{1,3}-[^AIQSZ]MN$)|(^([^IQSZ]-)?([1-9]{1,4}-MA?N)$)|(^MANX-[1-9]{1,3}$)|(^[1-9]{1,3}-MANX$)/;

export const combinedRegistrationRegex =
  /(^[A-Z]{2}[0-9]{2}[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[A-Z]{3}[1-9]{1,4}$)|(^(MN|MAN)-[1-9]{1,4}$)|(^MAN-[1-9]{1,3}-[^IQSZ]$)|(^([^AIQSZ]MN-[1-9]{1,3})(-[^IQSZ]{1})?$)|(^[1-9]{1,3}-[^AIQSZ]MN$)|(^([^IQSZ]-)?([1-9]{1,4}-MA?N)$)|(^MANX-[1-9]{1,3}$)|(^[1-9]{1,3}-MANX$)/;

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const postcodeRegex =
  /^(GIR 0AA)|^((([A-PRSTUWYZ a-prstuwyz][0-9][0-9]?)|^(([A-PRSTUWYZ a-prstuwyz][A-HK-Y a-hk-y][0-9][0-9]?)|^(([A-PRSTUWYZ a-prstuwyz][0-9][A-HJKSTUW a-hjkstuw])|^([A-PRSTUWYZ a-prstuwyz][A-HK-Y a-hk-y][0-9][ABEHMNPRVWXY abehmnprvwxy]))))\s?[0-9][ABD-HJLNP-UWXYZ abd-hjlnp-uwxyz]{2})/;

export const landlineNumberRegexp = /^(?:\+44\s?|0)[1238]\d\s?(?:\d\s?){7,8}$/gm;

export const uppercaseRegex = /[A-Z]/;
export const lowercaseRegex = /[a-z]/;
export const numberRegex = /\d/;
export const symbolRegex = /\W/;
