import { useCallback } from 'react';
import {
  NavigateFunction,
  NavigateOptions,
  Path,
  To,
  useNavigate,
} from 'react-router-dom';
import useSearchParamsToBuildPermittedQueryParams from '../helpers/url/permittedQueryStringBuilderFromSearchParams';

const isDelta = (arg: unknown): arg is number => {
  return typeof arg === 'number';
};

const isPath = (to: To): to is Partial<Path> => {
  return typeof to !== 'string';
};

const useNavigateWithReferrer = () => {
  const navigate = useNavigate();
  const permittedQueryParams = useSearchParamsToBuildPermittedQueryParams();

  const navigateWithReferrer: NavigateFunction = useCallback(
    (to: To | number, options?: NavigateOptions | undefined) => {
      if (isDelta(to)) navigate(to);
      else if (!permittedQueryParams) {
        navigate(to, options);
      } else if (isPath(to)) {
        navigate(
          {
            pathname: to.pathname,
            search: to.search
              ? `${to.search}&${permittedQueryParams}`
              : `${permittedQueryParams}`,
            hash: to.hash,
          },
          options
        );
      } else {
        navigate(
          to.includes('?')
            ? `${to}&${permittedQueryParams}`
            : `${to}?${permittedQueryParams}`,
          options
        );
      }
    },
    [navigate, permittedQueryParams]
  );

  return navigateWithReferrer;
};

export default useNavigateWithReferrer;
