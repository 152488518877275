import * as Yup from 'yup';
import {
  uppercaseRegex,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
} from '../../../constants/regex';

export type NewPasswordFormData = {
  password: string;
  confirmPassword: string;
};

export const NewPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Too short')
    .matches(uppercaseRegex, {
      message: 'One uppercase letter',
      excludeEmptyString: true,
    })
    .matches(lowercaseRegex, {
      message: 'One lowercase letter',
      excludeEmptyString: true,
    })
    .matches(numberRegex, {
      message: 'One number',
      excludeEmptyString: true,
    })
    .matches(symbolRegex, {
      message: 'One symbol',
      excludeEmptyString: true,
    })
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], {
      message: 'Passwords do not match',
      excludeEmptyString: true,
    })
    .required(),
});

export const getNewPasswordFormInitialData = (
  initialValues?: Partial<NewPasswordFormData>
): NewPasswordFormData => ({
  password: '',
  confirmPassword: '',
  ...initialValues,
});
