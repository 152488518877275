import clsx from 'clsx';
import FormattedPrice from '../../DataDisplay/FormattedPrice/FormattedPrice';
import Icon, { IconNames } from '../../DataDisplay/Icon/Icon';

export type CheckboxSelectorProps = {
  additionalIcon?: IconNames;
  id: string;
  name: string;
  displayText: string;
  isChecked?: boolean;
  onChange: (option: {
    id: string;
    name: string;
    checked: boolean;
    dialog?: string;
    price?: number;
    pricePrefix?: string;
    priceSufix?: string;
  }) => void;
  error?: boolean;
  disabled?: boolean;
  price?: number;
  pricePrefix?: string;
  priceSufix?: string;
  tooltip?: {
    ariaLabel: string;
    /** Setting this to true will stop the state of the input from updating.
     * State management of the input will need to be handled externally */
    triggerOnSelectorClick?: boolean;
    onClick: (id: string, name: string, checked: boolean) => void;
  };
  testId?: string;
  noBorders?: boolean;
  enlarged?: boolean;
};

const CheckboxSelector = ({
  additionalIcon,
  id,
  name,
  displayText,
  isChecked,
  onChange,
  error,
  disabled,
  price,
  pricePrefix,
  priceSufix,
  tooltip,
  testId,
  noBorders,
  enlarged,
}: CheckboxSelectorProps): JSX.Element => {
  const onChangeHandler = () => {
    if (tooltip?.triggerOnSelectorClick) {
      tooltip.onClick(id, name, !isChecked);
    } else {
      onChange({
        id,
        name: displayText,
        checked: !isChecked,
        price,
        priceSufix,
        pricePrefix,
      });
    }
  };

  return (
    <label
      htmlFor={id}
      className={clsx(
        'flex gap-3 py-5 h-auto rounded-lg px-4 md:px-8',
        { 'border-2 selector-base': !noBorders },
        { 'flex items-center w-full': noBorders },
        { 'border-selector-border-1 bg-selector-active': isChecked && !noBorders },
        { 'border-error-border': error },
        { 'border-main-content-3': !isChecked && !error },
        { 'opacity-40': disabled },
        { 'cursor-pointer': !disabled },
        { 'p-10': enlarged }
      )}
    >
      <input
        id={id}
        type="checkbox"
        name={name}
        checked={isChecked}
        onChange={onChangeHandler}
        className={clsx(
          'shrink-0 w-6 h-6 bg-transparent checked:bg-white rounded-md border-2 checked:border-white transition duration-200 appearance-none border-selector-border-1',
          { 'cursor-pointer': !disabled },
          { 'checked:bg-selector-active checked:border-selector-border-1': noBorders }
        )}
        disabled={disabled}
        data-testid={testId}
        data-checked={isChecked}
      />
      {isChecked && (
        <Icon
          className={clsx('absolute z-10 ml-1 pointer-events-none text-selector-active', {
            'text-selector-active-text': noBorders,
          })}
          name="check"
        />
      )}

      <div
        className={clsx(
          'grid grid-cols-[auto_1fr] justify-center items-center',
          additionalIcon && 'gap-5 pl-4'
        )}
      >
        {additionalIcon && (
          <Icon
            className="text-main-content-1"
            size="2rem"
            name={additionalIcon}
            color={isChecked ? 'white' : undefined}
          />
        )}
        <p
          className={clsx(
            'block font-raleway font-semibold truncate whitespace-normal',
            { 'text-main-content-1': !isChecked },
            { 'text-selector-active-text': isChecked && !noBorders }
          )}
        >
          {displayText}
        </p>
      </div>

      {tooltip && (
        <button
          aria-label={tooltip.ariaLabel}
          type="button"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            tooltip.onClick(id, name, !isChecked);
          }}
        >
          <Icon
            name="info"
            className={clsx(
              { 'text-main-content-1': !isChecked },
              { 'text-selector-active-text': isChecked }
            )}
          />
        </button>
      )}
      {price && (
        <p
          className={clsx(
            'flex right-6 z-10 p-2 ml-auto text-lg',
            { 'text-main-content-1': !isChecked },
            { 'text-selector-active-text': isChecked }
          )}
        >
          <FormattedPrice
            price={price}
            size="LG"
            primaryFontSize="text-base md:text-lg"
            prefix={pricePrefix}
            sufix={priceSufix}
          />
        </p>
      )}
    </label>
  );
};

export default CheckboxSelector;
