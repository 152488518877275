import {
  CoverLengthFormData,
  QuoteJourneyFormData,
} from '../../../../helpers/forms/quote';
import { AssetProperties, Price, Quote } from '../../../../api';

const getVehicleName = (vehicle?: AssetProperties): string => {
  if (!vehicle) {
    return '';
  }

  return `${vehicle.vehicleMake} ${vehicle.vehicleModel} ${vehicle.vehicleEngineSize}cc, ${vehicle.vehicleDoors}dr ${vehicle.vehicleFuelType} ${vehicle.vehicleTransmission} (${vehicle.vehicleStartYear}-${vehicle.vehicleEndYear})`;
};

const getVehicleModelFromQuoteDetails = (assetProperties?: AssetProperties) => {
  if (!assetProperties) {
    return null;
  }

  return {
    id: assetProperties?.vehicleAbiCode || '',
    name: getVehicleName(assetProperties),
  };
};

const getCoverLengthFromQuoteDetails = (
  quoteDetails?: Quote
): CoverLengthFormData['coverLength'] | null => {
  if (!quoteDetails?.duration) {
    return null;
  }

  return {
    ...quoteDetails?.duration,
    id: `${quoteDetails?.duration?.value} ${quoteDetails?.duration?.type}`,
  };
};

type SummaryCardQuoteData = {
  excess?: number;
  firstName?: string;
  surname?: string;
  coverLength?: CoverLengthFormData['coverLength'];
  price?: Price;
  product?: string;
  underwriter?: string;
  vehicleRegistration?: string;
  vehicleModel?: {
    id: string;
    name: string;
  };
};

export const sourceOfTruthFormStateRoutes = [
  'driver-details',
  'contact-details',
  'learner-driver',
];

export const displayDataFromApi = (currentRoute: string): boolean =>
  !sourceOfTruthFormStateRoutes.some(path => currentRoute.includes(path));

export const getQuoteData = (
  isFromApi: boolean,
  formState: QuoteJourneyFormData,
  quoteDetails?: Quote
): SummaryCardQuoteData => {
  const vehicleQuoteData = quoteDetails?.assets?.[0].properties;

  if (isFromApi && quoteDetails) {
    return {
      excess: quoteDetails?.excess,
      firstName: quoteDetails?.persons?.[0].firstName || '',
      surname: quoteDetails?.persons?.[0].lastName || '',
      coverLength: getCoverLengthFromQuoteDetails(quoteDetails) || undefined,
      price: quoteDetails?.price,
      product: quoteDetails?.product,
      underwriter: quoteDetails?.underwriterName || '',
      vehicleRegistration: vehicleQuoteData?.vehicleRegistration || '',
      vehicleModel: getVehicleModelFromQuoteDetails(vehicleQuoteData) || undefined,
    };
  }

  return {
    excess: formState.excess,
    firstName: formState.firstName,
    surname: formState.surname,
    coverLength: formState.coverLength,
    price: formState.price,
    product: formState.product,
    underwriter: formState.underwriter,
    vehicleRegistration: formState.vehicleRegistration,
    vehicleModel: formState.vehicleModel,
  };
};

// On extend policy review and extend policy payment page we receive quote request id instead of quote id
// removing it prevents from requesting the backend API and throwing error with wrong quote id
export const removeRequestQuoteId = (
  currentRoute: string,
  quoteId?: string
): string | undefined => {
  if (
    currentRoute.includes('policies') &&
    currentRoute.includes('extend') &&
    (currentRoute.includes('review') || currentRoute.includes('payment'))
  ) {
    return undefined;
  }

  return quoteId;
};
