import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  AssetType,
  DayInsureAPI,
  OpenAPI,
  ProductName,
  UnderWriterName,
} from '../../api';
import { DocumentContext } from '../../contexts/DocumentContext/DocumentContext';
import useReferrer from '../useReferrer';

export enum DocumentType {
  ExcessCertificate = 'ExcessCertificate',
  PolicyCertificate = 'PolicyCertificate',
  NotSpecified = 'NotSpecified',
  Assumptions = 'Assumptions',
  Keyfacts = 'Keyfacts',
  UkCoverPolicyWording = 'UkCoverPolicyWording',
  Toba = 'Toba',
  BreakdownKeyFacts = 'BreakdownKeyFacts',
  BreakdownPolicyWording = 'BreakdownPolicyWording',
  EuropeanCoverPolicyWording = 'EuropeanCoverPolicyWording',
  LegalExpensesKeyFacts = 'LegalExpensesKeyFacts',
  LegalExpensesPolicyWording = 'LegalExpensesPolicyWording',
  Assumptions_Motorhome = 'Assumptions_Motorhome',
  LegalExpensesKeyFacts_Motorhome = 'LegalExpensesKeyFacts_Motorhome',
  LegalExpensesPolicyWording_Motorhome = 'LegalExpensesPolicyWording_Motorhome',
  UkCoverPolicyWording_Motorhome = 'UkCoverPolicyWording_Motorhome',
  InsuranceProductInformationDocument = 'InsuranceProductInformationDocument',
  UkCoverKeyFacts_Motorhome = 'UkCoverKeyFacts_Motorhome',
  PrivacyPolicy = 'PrivacyPolicy',
  ExcessProtectionPolicyWording = 'ExcessProtectionPolicyWording',
  ExcessProtectionKeyFacts = 'ExcessProtectionKeyFacts',
}

/**
 * Queries for document links. Used to get hrefs for various links around
 * the site. Uses {@link DocumentContext} to provide referrer/vehicle/underwriter
 * specific results.
 *
 * Pass in options to override the config values retrieved from context
 */
const useDocumentQuery = (
  documentType: DocumentType,
  options?: {
    underwriter?: UnderWriterName;
    product?: ProductName;
    assetType?: AssetType;
  }
) => {
  const {
    underwriter: underwriterContext,
    product: productContext,
    assetType: assetTypeContext,
  } = useContext(DocumentContext);
  const { name: referrerName } = useReferrer();

  const getDocument = () => {
    const api = new DayInsureAPI(OpenAPI);
    return api.document.getApiV1Document(
      documentType,
      options?.underwriter || underwriterContext,
      options?.product || productContext,
      referrerName,
      options?.assetType || assetTypeContext
    );
  };

  return useQuery(
    [
      'document',
      documentType,
      options?.underwriter || underwriterContext,
      options?.product || productContext,
      options?.assetType || assetTypeContext,
    ],
    getDocument,
    { cacheTime: 180000 }
  );
};

export default useDocumentQuery;
