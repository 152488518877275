/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PolicyState {
  UPCOMING = 'Upcoming',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  CANCELLED = 'Cancelled',
  CANCELLATION_REQUESTED = 'CancellationRequested',
}