import { useFormikContext } from 'formik';
import { useLocation, useSearchParams } from 'react-router-dom';
import { QuoteJourneyFormData } from '../../../helpers/forms/quote';
import RouterState from '../../../types/RouterState';
import NavigateWithReferrer from '../../Common/Links/NavigateWithReferrer';

type QuoteJourneyGuardProps = {
  redirect: string;
  children: JSX.Element | JSX.Element[];
};

// Redirects to the beginning of the quote flow if the user hasn't come from the start
const QuoteJourneyGuard = ({ redirect, children }: QuoteJourneyGuardProps) => {
  const [params] = useSearchParams();
  const location = useLocation();
  const state = location.state as RouterState;

  const MD = params.get('MD');
  const PaRes = params.get('PaRes');
  const hasReturnedFrom3DS1Redirect = MD && PaRes;

  const {
    values: { journeyStartPosition },
  } = useFormikContext<QuoteJourneyFormData>();

  if (
    journeyStartPosition === undefined &&
    !hasReturnedFrom3DS1Redirect &&
    !state?.returnFromSignInRedirect &&
    !state?.editingQuote
  ) {
    return <NavigateWithReferrer to={redirect} replace />;
  }

  return <>{children}</>;
};

export default QuoteJourneyGuard;
