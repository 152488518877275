import clsx from 'clsx';
import { useId } from 'react';

type SpinnerSvgProps = {
  className?: string;
};

const SpinnerSvg = ({ className }: SpinnerSvgProps): JSX.Element => {
  const gradient1Id = useId();
  const gradient2Id = useId();
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.3333 21.3339C46.8061 21.3339 48 22.5278 48 24.0006C48 30.3656 45.4715 36.47 40.9707 40.9707C36.47 45.4715 30.3656 48 24.0006 48L24.0006 42.6667C28.9511 42.6667 33.6989 40.7001 37.1995 37.1995C40.7001 33.6989 42.6667 28.9511 42.6667 24.0006C42.6667 22.5278 43.8606 21.3339 45.3333 21.3339Z"
        fill={`url(#${gradient1Id})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4163 5.33435C25.889 5.34969 27.0953 4.16828 27.1106 2.6956C27.1259 1.22292 25.9445 0.0166434 24.4719 0.00130216C24.3097 -0.000386643 24.1476 -0.000432758 23.9855 0.00116381C17.6255 0.00484222 11.5268 2.53294 7.02927 7.03043C2.5285 11.5312 2.78225e-07 17.6355 0 24.0006C-2.78225e-07 30.3656 2.5285 36.47 7.02927 40.9707C11.53 45.4715 17.6344 48 23.9994 48V42.6667C19.0489 42.6667 14.3011 40.7001 10.8005 37.1995C7.29994 33.6989 5.33334 28.9511 5.33334 24.0006C5.33334 19.05 7.29994 14.3022 10.8005 10.8017C14.3011 7.30109 19.0489 5.33449 23.9994 5.3345C24.0087 5.33449 24.0181 5.33445 24.0274 5.33435C24.157 5.333 24.2867 5.333 24.4163 5.33435Z"
        fill={`url(#${gradient2Id})`}
      />
      <defs>
        <linearGradient
          id={gradient1Id}
          x1="24.5"
          y1="21.5"
          x2="24.5"
          y2="42.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentcolor" stopOpacity="0" />
          <stop offset="1" stopColor="currentcolor" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          id={gradient2Id}
          x1="24"
          y1="5.5"
          x2="24"
          y2="42.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentcolor" />
          <stop offset="1" stopColor="currentcolor" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export type LoadingSpinnerProps = {
  /** The preset sizes for the spnner. Small size is 1.5rem. Regular size is is 3rem. */
  size?: 'tiny' | 'small' | 'regular';
  /** The color variant. Whether the loading spinner is on a card or a cta */
  variant?: 'card' | 'cta';
  /** When set, centers the loading spinner horizontally within its parent container */
  centered?: boolean;
  /** Can be used to override the spinner classes. Can be used in conjunction with `size=custom` to set a custom size */
  className?: string;
  /** Id used for testing */
  testId?: string;
  /** Use secondary color scheme */
  secondary?: boolean;
};

const LoadingSpinner = ({
  size = 'regular',
  variant = 'card',
  centered = false,
  secondary = false,
  className,
  testId,
}: LoadingSpinnerProps): JSX.Element => {
  return (
    <div
      className={clsx(
        'opacity-60',
        {
          'flex justify-center w-full': centered,
          'inline-block align-top': !centered,
          'text-main-content-1': variant === 'card',
          'text-cta-primary-text': !secondary && variant === 'cta',
          'text-cta-secondary-text': secondary && variant === 'cta',
        },
        className
      )}
      data-testid={testId}
    >
      <SpinnerSvg
        className={clsx('animate-spin-fast', {
          'w-4 h-4': size === 'tiny',
          'w-6 h-6': size === 'small',
          'w-12 h-12': size === 'regular',
        })}
      />
    </div>
  );
};

export default LoadingSpinner;
