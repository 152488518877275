import { ReactNode } from 'react';

type ResponsiveContainerProps = {
  children?: ReactNode;
  className?: string;
};

const ResponsiveContainer = ({ children, className }: ResponsiveContainerProps) => {
  return (
    <div
      className={`px-6 md:px-0 lg:px-6 mx-auto sm:max-w-tablet-page md:min-w-tablet-page lg:max-w-desktop-page  ${className}`}
    >
      {children}
    </div>
  );
};

export { ResponsiveContainer };
