import {
  AssetType,
  CreatePersonRequest,
  CreateQuoteRequest,
  LicenceType,
  PersonType,
  QuotePersonType,
  UpdatePersonRequest,
  UpdateQuoteRequest,
} from '../../../api';
import Referrer from '../../../types/referrer';
import { QuoteJourneyFormData } from './QuoteJourneySchema';
import trimWhiteSpaces from './trimWhiteSpaces/trim-white-spaces';

export const getCreateGuestAccountRequestBody = (
  formData: QuoteJourneyFormData,
  referrer: Referrer
): CreatePersonRequest => ({
  address: formData.address,
  dateOfBirth: formData.dateOfBirth.parsedDate,
  drivingLicenceNumber: formData.licenceNumber,
  email: formData.emailAddress,
  firstName: trimWhiteSpaces(formData.firstName),
  identityProvider: referrer.identityProviderName,
  lastName: trimWhiteSpaces(formData.surname),
  mainPhoneNumber: formData.mobileNumber,
  occupation: { id: formData.occupation?.id, description: formData.occupation?.name },
  type: PersonType.GUEST,
  referrer: referrer.name,
});

export const getUpdateAccountRequestBody = (
  formData: QuoteJourneyFormData
): UpdatePersonRequest => ({
  address: {
    ...formData.address,
    // Workaround for API issue where country we get back from GET quote is set to United Kingdom
    // instead of GBR
    country:
      formData.address?.country == null || formData.address?.country === 'United Kingdom'
        ? 'GBR'
        : formData.address?.country,
  },
  dateOfBirth: formData.dateOfBirth.parsedDate,
  drivingLicenceNumber: formData.licenceNumber,
  email: formData.emailAddress,
  firstName: trimWhiteSpaces(formData.firstName),
  lastName: trimWhiteSpaces(formData.surname),
  phoneNumber: formData.mobileNumber,
  occupation: { id: formData.occupation?.id, description: formData.occupation?.name },
});

export const getCreateQuoteRequestBody = (
  formData: QuoteJourneyFormData,
  personId: string,
  referrer: Referrer,
  referrerChannel: string | null
): CreateQuoteRequest => ({
  persons: [
    {
      id: personId,
      quotePersonType: QuotePersonType.POLICY_HOLDER,
      licenceType:
        formData.registeredKeeper?.id === undefined
          ? LicenceType.FULL
          : LicenceType.PROVISIONAL,
      marketingPreferences: formData.marketingPreferences.map(pref => ({
        type: pref.id,
        isConsented: pref.checked,
      })),
    },
  ],
  assets: [
    {
      type: formData.vehicleType?.id as unknown as AssetType,
      properties: {
        vehicleRegistration: formData.vehicleRegistration,
        vehicleAbiCode: formData.vehicleModel?.id,
        isConvertedToMotorhome: formData.isConvertedToMotorhome,
      },
    },
  ],
  referrer: referrer.name,
  referrerChannel,
});

export const getCreateRegisteredKeeperQuoteRequestBody = (
  formData: QuoteJourneyFormData,
  personId: string,
  referrer: Referrer,
  referrerChannel: string | null
): CreateQuoteRequest => ({
  persons: [
    {
      id: personId,
      quotePersonType: QuotePersonType.POLICY_HOLDER,
      licenceType: LicenceType.PROVISIONAL,
      marketingPreferences: formData.marketingPreferences.map(pref => ({
        type: pref.id,
        isConsented: pref.checked,
      })),
    },
    {
      quotePersonType: QuotePersonType.ASSET_OWNER,
      firstName: trimWhiteSpaces(formData.registeredKeeperFirstName),
      lastName: trimWhiteSpaces(formData.registeredKeeperSurname),
      mainPhoneNumber: formData.registeredKeeperMobileNumber,
      address: {
        ...formData.registeredKeeperAddress,
        // Workaround for API issue where country we get back from GET quote is set to United Kingdom
        // instead of GBR
        country:
          !formData.registeredKeeperAddress?.country ||
          formData.registeredKeeperAddress?.country === 'United Kingdom'
            ? 'GBR'
            : formData.registeredKeeperAddress?.country,
      },
    },
  ],
  assets: [
    {
      type: formData.vehicleType?.id as unknown as AssetType,
      properties: {
        vehicleRegistration: formData.vehicleRegistration,
        vehicleAbiCode: formData.vehicleModel?.id,
        isConvertedToMotorhome: formData.isConvertedToMotorhome,
      },
    },
  ],
  referrer: referrer.name,
  referrerChannel,
});

export const getUpdateQuoteRequestBody = (
  formData: QuoteJourneyFormData,
  blackBoxId: string | undefined,
  runFraudCheck?: boolean
): UpdateQuoteRequest & { id: string } => ({
  id: formData.quoteId || '',
  duration: { type: formData.coverLength?.type, value: formData.coverLength?.value },
  selectedAddOns: formData.addOns?.filter(addon => addon.checked).map(addon => addon.id),
  runFraudCheck,
  blackBoxId,
});
