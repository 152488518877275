import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Card, Dialog, FormattedPrice } from '@dayinsure/components';
import { Price } from '../../../../api';
import BreakdownItem from './BreakdownItem';
import useReferrer from '../../../../hooks/useReferrer';

const getFontColor = (referrerName: string) =>
  referrerName === 'Halifax' ? 'text-popup-content-1' : 'text-popup-content-2';

type QuoteSummaryDialogProps = {
  price: Price;
  open: boolean;
  isLearner: boolean;
  excess: any;
  underwriter: any;
  onClose: () => void;
};

const parseFeeValue = (fee?: number) => (typeof fee === 'number' ? Math.abs(fee) : fee);

const QuoteSummaryDialog = ({
  price,
  open,
  isLearner,
  underwriter,
  excess,
  onClose,
}: QuoteSummaryDialogProps) => {
  const { t } = useTranslation('quotes');
  const { name } = useReferrer();

  const sortedFees = price.items
    ?.filter(fee => fee.type !== 'AdminFee')
    .filter(fee => fee.name !== 'Discount')
    .sort(a => (a.type === 'InsurancePremium' ? -1 : 0));
  const adminFee = price.items?.find(fee => fee.type === 'AdminFee');

  const discount = price.items?.find(fee => fee?.name === 'Discount');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('summary.dialog.breakdown_title')}
      hasCrossCloseButton
    >
      <Card
        classNames="!bg-popup-background flex flex-col items-center mb-6 !py-4 !px-0"
        paddingLevel="small"
      >
        <>
          <BreakdownItem
            title={t('summary.dialog.cover_type')}
            value={t('summary.dialog.comprehensive')}
            testId="price-breakdown-cover-type"
            addDivider
          />

          {!isLearner && (
            <BreakdownItem
              title={t('summary.dialog.legal_fees')}
              value={t('summary.dialog.included')}
              testId="price-breakdown-legal-fees"
              addDivider
            />
          )}

          {sortedFees && adminFee && (
            <>
              {sortedFees &&
                sortedFees.map(fee => {
                  return (
                    <BreakdownItem
                      key={fee.type}
                      title={fee.name || ''}
                      value={
                        <FormattedPrice price={parseFeeValue(fee.value)} size="BASE" />
                      }
                      testId={`price-breakdown-${fee.type}`}
                      addDivider
                    />
                  );
                })}

              <span className={clsx(['px-4 text-sm', getFontColor(name)])}>
                {t('summary.dialog.tax_message')}
              </span>
              <hr className="my-4 -mx-4 w-full border-y border-popup-background" />

              {adminFee && (
                <BreakdownItem
                  title={t('summary.dialog.admin_fee')}
                  value={<FormattedPrice price={adminFee.value} size="BASE" />}
                  testId="price-breakdown-admin-fee"
                />
              )}

              {discount && (
                <>
                  <hr className="my-4 -mx-4 w-full border-y border-popup-background" />
                  <BreakdownItem
                    title={discount.name || ''}
                    value={
                      <FormattedPrice price={Math.abs(discount.value || 0)} size="BASE" />
                    }
                    testId="price-breakdown-discount"
                  />
                </>
              )}
            </>
          )}
        </>
      </Card>
      {price && (
        <Card
          classNames="!bg-popup-background flex flex-col items-center gap-2 !p-4"
          paddingLevel="small"
        >
          <span className="text-sm text-popup-content-1">
            {t('summary.dialog.total')}
          </span>
          <span
            className="text-lg leading-7 text-popup-title"
            id="price-breakdown-total-price"
          >
            {price.total && <FormattedPrice price={price.total} size="LG" />}
          </span>
          <span className={clsx(['text-sm', getFontColor(name)])}>
            {t('summary.dialog.excess', {
              excessAmount: excess,
              underwriter,
            })}
          </span>
        </Card>
      )}

      <Button
        id="price-breakdown-close-btn"
        fullWidth
        onClick={onClose}
        text={t('summary.dialog.close')}
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};

export default QuoteSummaryDialog;
