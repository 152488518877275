/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateQuoteRequest } from '../models/CreateQuoteRequest';
import type { PolicyResponseIAPIResult } from '../models/PolicyResponseIAPIResult';
import type { QuoteAuthDetailsIAPIResult } from '../models/QuoteAuthDetailsIAPIResult';
import type { QuoteIAPIResult } from '../models/QuoteIAPIResult';
import type { QuoteSummaryResponseIAPIResult } from '../models/QuoteSummaryResponseIAPIResult';
import type { ResumePurchaseQuoteRequest } from '../models/ResumePurchaseQuoteRequest';
import type { UpdateQuoteRequest } from '../models/UpdateQuoteRequest';
import type { WebDocumentListIAPIResult } from '../models/WebDocumentListIAPIResult';
import type { WebPurchaseQuoteRequest } from '../models/WebPurchaseQuoteRequest';
import type { WebPurchaseQuoteResponseIAPIResult } from '../models/WebPurchaseQuoteResponseIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QuoteService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Authorized - Creates an insurance quote
   * @param requestBody REQUIRED - A Request that contains quote information
   * @returns QuoteSummaryResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1Quote(
    requestBody?: CreateQuoteRequest,
  ): CancelablePromise<QuoteSummaryResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Retrieves quote details for a given quote ID
   * @param id A unique ID for a quote
   * @returns QuoteIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Quote(
    id: string,
  ): CancelablePromise<QuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Updates an existing quote given a different start date, a different duration and/or different add ons.
   * @param id An ID representing a quote
   * @param requestBody An Object representing the properties of a quote you want to update
   * @returns QuoteSummaryResponseIAPIResult Success
   * @throws ApiError
   */
  public putApiV1Quote(
    id: string,
    requestBody?: UpdateQuoteRequest,
  ): CancelablePromise<QuoteSummaryResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/quote/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Authorized - Purchase a quote, providing it is eligible. Completing this will result in a new policy. Failing payment will allow the payment to be retried.
   * @param id The ID of the quote - REQUIRED
   * @param requestBody REQUIRED - The payment method, required fields for the payment method in Adyen encrypted format are all required
   * @returns WebPurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1QuotePurchase(
    id: string,
    requestBody?: WebPurchaseQuoteRequest,
  ): CancelablePromise<WebPurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote/{id}/purchase',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        503: `Server Error`,
      },
    });
  }

  /**
   * Authorized - Resume Purchase of a quote. This is required if additional actions were required as a result of previous calls to purchase or resume purchase of quote. Completing this will result in a new policy. Failing payment will allow the payment to be retried.
   * @param id The ID of the quote - REQUIRED
   * @param requestBody REQUIRED - The payment method, required fields for the payment method in Adyen encrypted format are all required
   * @returns WebPurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1QuoteResumePurchase(
    id: string,
    requestBody?: ResumePurchaseQuoteRequest,
  ): CancelablePromise<WebPurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote/{id}/resume-purchase',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Authorized - Get a policy by the quote id provided.
   * @param id REQUIRED
   * @returns PolicyResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1QuotePolicy(
    id: string,
  ): CancelablePromise<PolicyResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/policy',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Retrieves quote and provide status of either quote or policy
   * @param id
   * @returns QuoteAuthDetailsIAPIResult Success
   * @throws ApiError
   */
  public getApiV1QuoteAuthDetails(
    id: string,
  ): CancelablePromise<QuoteAuthDetailsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/auth-details',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Get a policy by the quote id provided.
   * @param id REQUIRED
   * @returns WebDocumentListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1QuoteDocuments(
    id: string,
  ): CancelablePromise<WebDocumentListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/documents',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

}