import clsx from 'clsx';
import React, { useState } from 'react';
import Icon from '../../DataDisplay/Icon/Icon';

type CarouselItemProps = {
  children: JSX.Element;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  ariaLabel: string;
};

type CarouselProps = {
  children: JSX.Element | JSX.Element[];
  nextSlideAriaLabel: string;
  nextSlideButtonId: string;
  className?: string;
};

export const CarouselItem = ({ children, className }: CarouselItemProps) => {
  return <div className={clsx('inline-flex w-full', className)}>{children}</div>;
};

const Carousel = ({
  children,
  nextSlideAriaLabel,
  nextSlideButtonId,
  className,
}: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex: number) => {
    let newActiveIndex = newIndex;
    if (newIndex < 0) {
      newActiveIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newActiveIndex = 0;
    }

    setActiveIndex(newActiveIndex);
  };

  return (
    <div
      className={`flex flex-col justify-between overflow-hidden w-full rounded-lg bg-content-background-prominent text-main-content-1 ${className}`}
    >
      <div
        className="whitespace-nowrap transition-transform duration-300"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, child => {
          return React.cloneElement(child, { width: '100%' });
        })}
      </div>

      {React.Children.count(children) > 1 && (
        <div className="flex top-auto bottom-0 justify-center items-center mx-4 mb-4 sm:mx-6 sm:mb-6 lg:mx-8 lg:mb-8">
          <div className="flex space-x-2">
            {React.Children.map(children, (child, index) => {
              return (
                // eslint-disable-next-line react/self-closing-comp
                <button
                  aria-label={`${child.props.ariaLabel} ${index}`}
                  type="button"
                  className={clsx(
                    'w-2 h-2 rounded-full',
                    {
                      'bg-main-content-1': index === activeIndex,
                    },
                    { 'bg-main-content-4': index !== activeIndex }
                  )}
                  onClick={() => {
                    updateIndex(index);
                  }}
                ></button>
              );
            })}
          </div>
          <button
            id={nextSlideButtonId}
            type="button"
            aria-label={nextSlideAriaLabel}
            className="p-2 -m-2 ml-auto"
            onClick={() => {
              updateIndex(activeIndex + 1);
            }}
          >
            <Icon name="chevron-right" size="0.75rem" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
