import React, { ComponentType, LazyExoticComponent } from 'react';

export type LazyFactory<T> = () => Promise<{ default: T }>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyWithPreload = <T extends ComponentType<any>>(
  factory: LazyFactory<T>
): [LazyExoticComponent<T>, LazyFactory<T>] => {
  const Component = React.lazy(factory);
  return [Component, factory];
};
