import { LoadingSpinner } from '@dayinsure/components';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import usePerson from '../../../hooks/usePerson';
import useSignIn from '../../../hooks/useSignIn';
import NavigateWithReferrer from '../../Common/Links/NavigateWithReferrer';

type ProtectedRouteProps = {
  redirect?: string;
  children: JSX.Element | JSX.Element[];
};

const ProtectedRoute = ({ redirect = '/', children }: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading, signinSilent, user, removeUser } = useAuth();
  const { person, isGuest } = usePerson();
  const { signIn } = useSignIn();

  const [isAttemptingRefresh, setIsAttemptingRefresh] = useState(false);
  const [refreshFailed, setRefreshFailed] = useState(false);

  // If the user isn't authenticated, but we think we can refresh their token, do so.
  // If we fail to refresh the token, wipe the users login.
  useEffect(() => {
    if (!isLoading && !isAuthenticated && user?.refresh_token && !refreshFailed) {
      setIsAttemptingRefresh(true);
      signinSilent()
        .catch(() => {
          setRefreshFailed(true);
          removeUser();
        })
        .finally(() => {
          setIsAttemptingRefresh(false);
        });
    }
  }, [isLoading, isAuthenticated, user, signinSilent, refreshFailed, removeUser]);

  // Whilst auth is loading or we are attempting a refresh
  if (isLoading) {
    return null;
  }

  // If they are not authenticated, first attempt to refresh the token
  if (
    (!isAuthenticated && !refreshFailed && user?.refresh_token) ||
    isAttemptingRefresh
  ) {
    return <LoadingSpinner centered className="mt-8" />;
  }

  // If the token refresh has failed, sign in
  if (!isAuthenticated && (refreshFailed || !user?.refresh_token)) {
    signIn();
    return <LoadingSpinner centered className="mt-8" />;
  }

  // If they are a guest user, redirect
  if (isGuest) {
    return <NavigateWithReferrer to={{ pathname: redirect }} replace />;
  }

  // Hold off on rendering until we've retrieved info about the person
  if (!person) {
    return null;
  }

  // Render the component
  return <>{children}</>;
};

export default ProtectedRoute;
