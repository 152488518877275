/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MarketConsentType {
  EMAIL = 'Email',
  SMS = 'SMS',
  TELEPHONE = 'Telephone',
  POST = 'Post',
  MARKET_RESEARCH = 'MarketResearch',
}