import Icon from '@dayinsure/components/src/components/DataDisplay/Icon/Icon';
import { useTranslation } from 'react-i18next';
import useDocumentQuery, { DocumentType } from '../../hooks/queries/useDocumentQuery';
import useReferrer from '../../hooks/useReferrer';
import { DEFAULT_TOBA_URL } from '../../constants/apps';

const Footer = (): JSX.Element => {
  const { t } = useTranslation('footer');
  const referrer = useReferrer();

  const { data: toba } = useDocumentQuery(DocumentType.Toba);
  const { data: privacyPolicy } = useDocumentQuery(DocumentType.PrivacyPolicy);

  let privacyPolicyUrl = 'https://www.dayinsure.com/dayinsure-privacy-policy/';
  if (
    referrer.useWebsiteDocuments === false &&
    privacyPolicy?.detail?.documentUrl != null
  ) {
    privacyPolicyUrl = privacyPolicy.detail.documentUrl;
  }

  return (
    <footer className="flex flex-col items-center pt-12 pb-16 mt-auto w-full text-center lg:pb-[4.5rem] bg-footer-background">
      <div className="flex flex-col items-center pb-6 text-center sm:flex-row lg:pb-4">
        <div className="pb-5 sm:pb-0">
          <a
            className="inline-flex gap-2 pr-4 pl-6 font-lato text-sm font-bold sm:pr-3 sm:pl-0 md:pr-6 text-footer-content-1 link-with-icon"
            href={referrer.footerDomain.href}
            id="footer-go-to-dayinsure-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('go_to_site', { site: referrer.footerDomain.displayName })}
            <Icon name="link" className="text-footer-content-1" size="10px" />
          </a>
        </div>
        <div className="flex space-x-4">
          <a
            href={toba?.detail?.documentUrl || DEFAULT_TOBA_URL}
            className="font-lato text-sm text-footer-content-1"
            id="footer-terms-of-business-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('terms_of_business')}
          </a>
          <a
            href={privacyPolicyUrl}
            className="font-lato text-sm text-footer-content-1"
            id="footer-privacy-policy-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('privacy_policy')}
          </a>
          <a
            href="https://www.dayinsure.com/cookie-policy/"
            className="font-lato text-sm text-footer-content-1"
            id="footer-cookie-policy-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('cookies')}
          </a>
        </div>
      </div>
      <div className="inline-flex text-center">
        <span className="px-6 font-lato text-sm text-footer-content-2">
          {referrer?.footerLegalWording.replace(
            '{{year}}',
            new Date().getFullYear().toString()
          ) || t('trademark', { year: new Date().getFullYear() })}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
