type FieldErrorProps = {
  /** The id of the error field. This will be referenced in the aria-errormessage tag on the field */
  id: string;
  /** The error message to display */
  error: string;
  /** Classes to be passed to the inner span. These can be used to position the error beneath the field */
  className?: string;
  /** id used for testing */
  testId?: string;
};

const FieldError = ({ id, error, className, testId }: FieldErrorProps) => {
  return (
    <span id={id} data-testid={testId} className={`text-xs text-error-text ${className}`}>
      {error}
    </span>
  );
};

export default FieldError;
