/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DurationType {
  MINUTE = 'Minute',
  HOUR = 'Hour',
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
}