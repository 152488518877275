/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ObjectIServiceResult } from '../models/ObjectIServiceResult';
import type { PaymentSessionRequest } from '../models/PaymentSessionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentSessionService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get ApplePay Payment Session
   * @param requestBody
   * @returns ObjectIServiceResult Success
   * @throws ApiError
   */
  public postApiV1PaymentSession(
    requestBody?: PaymentSessionRequest,
  ): CancelablePromise<ObjectIServiceResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payment-session',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
      },
    });
  }

}