/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IAPIResult } from '../models/IAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PolicySessionService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * link policy to an existing confirmed account
   * @param id session id
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicySessionLinkPolicyToAccount(
    id: string,
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy-session/{id}/link-policy-to-account',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }

}