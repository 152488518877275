import phone from 'phone';
import { landlineNumberRegexp } from '../../../../constants/regex';

const isValidPhoneNumber = (contactNumber: string): boolean =>
  phone(contactNumber, { country: 'GB' }).isValid;

const isValidLandLineNumber = (contactNumber: string): boolean =>
  !!contactNumber.match(landlineNumberRegexp);

const isValidContactNumber = (contactNumber: string): boolean =>
  isValidPhoneNumber(contactNumber) || isValidLandLineNumber(contactNumber);

export { isValidPhoneNumber, isValidLandLineNumber, isValidContactNumber };
