/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StringListIAPIResult } from '../models/StringListIAPIResult';
import type { VehicleFuelType } from '../models/VehicleFuelType';
import type { VehicleTransmissionType } from '../models/VehicleTransmissionType';
import type { VehicleType } from '../models/VehicleType';
import type { WebVehicleIAPIResult } from '../models/WebVehicleIAPIResult';
import type { WebVehicleTypeModelsIAPIResult } from '../models/WebVehicleTypeModelsIAPIResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VehicleService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Looks up a Vehicle using a third party provider given a vehicle registration
   * @param vehicleRegistration Vehicle Registration Mark
   * @returns WebVehicleIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Vehicle(
    vehicleRegistration: string,
  ): CancelablePromise<WebVehicleIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleRegistration}',
      path: {
        'vehicleRegistration': vehicleRegistration,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of vehicle makes given a vehicle type
   * @param vehicleType REQUIRED - Vehicle type
   * @param registrationYear OPTIONAL - Vehicle Registration Year
   * @returns StringListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1VehicleMake(
    vehicleType: VehicleType,
    registrationYear?: number,
  ): CancelablePromise<StringListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleType}/make',
      path: {
        'vehicleType': vehicleType,
      },
      query: {
        'registrationYear': registrationYear,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

  /**
   * Gets a list of vehicle models given a make and vehicle type
   * @param vehicleType REQUIRED - Vehicle type
   * @param make REQUIRED - Vehicle make
   * @param fuelType OPTIONAL - Vehicle fuel type
   * @param transmission OPTIONAL - Vehicle transmission
   * @param registrationYear OPTIONAL - Vehicle Registration Year
   * @returns WebVehicleTypeModelsIAPIResult Success
   * @throws ApiError
   */
  public getApiV1VehicleMakeModel(
    vehicleType: VehicleType,
    make: string,
    fuelType?: VehicleFuelType,
    transmission?: VehicleTransmissionType,
    registrationYear?: number,
  ): CancelablePromise<WebVehicleTypeModelsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleType}/make/{make}/model',
      path: {
        'vehicleType': vehicleType,
        'make': make,
      },
      query: {
        'fuelType': fuelType,
        'transmission': transmission,
        'registrationYear': registrationYear,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }

}