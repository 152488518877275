import * as Yup from 'yup';

export type PaymentFields = {
  encryptedCardNumber: string;
  cardholderName: string;
  encryptedExpiryDate: string;
  encryptedExpiryMonth: string;
  encryptedExpiryYear: string;
  encryptedSecurityCode: string;
  saveCardDetails: boolean;
};

// Adyen handles the majority of the validation - we just want to check
// that the fields have been filled
export const PaymentValidationSchema = Yup.object().shape({
  encryptedCardNumber: Yup.string().required('Required'),
  cardholderName: Yup.string().required('Required'),
  encryptedExpiryMonth: Yup.string().required('Required'),
  encryptedExpiryYear: Yup.string().required('Required'),
  encryptedSecurityCode: Yup.string().required('Required'),
});
