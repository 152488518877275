import { isBefore } from 'date-fns/fp';
import { QuoteJourneyFormData } from '../../../../helpers/forms/quote';

export const isStartDateInPast = (values: QuoteJourneyFormData): boolean => {
  if (values.coverStartQuestion?.id === 'ASAP' && values.asapStartDate) {
    return isBefore(new Date(), values.asapStartDate);
  }

  if (
    values.coverStartQuestion?.id === 'EXACT' &&
    values.exactStartDate &&
    values.exactStartHour?.id &&
    values.exactStartMinute?.id
  ) {
    const exactStartDate = new Date(values.exactStartDate.parsedDate || '');
    exactStartDate.setHours(parseInt(values.exactStartHour.id || '', 10));
    exactStartDate.setMinutes(parseInt(values.exactStartMinute.id || '', 10));

    return isBefore(new Date(), exactStartDate);
  }

  return false;
};
